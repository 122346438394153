import { combineReducers } from 'redux';
import types from './types';

const abTestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.SET_ID: {
      return {
        ...state,
        userId: payload,
      };
    }
    case types.SET_EXPERIMENTS: {
      return {
        ...state,
        experiments: payload,
      };
    }
    case types.SET_MOBILE: {
      return {
        ...state,
        isMobile: payload,
      };
    }
    case types.SET_INTERSTITIAL_SEEN: {
      return {
        ...state,
        interstitialSeen: payload,
      };
    }
    case types.SET_ALERT_SEEN: {
      return {
        ...state,
        alertSeen: payload,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  data: abTestReducer,
});
