import types from './types';

const userAgentReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.STORE_USER_AGENT: {
      const userAgentData = {
        platform: null,
        isIOS: false,
        isAndroid: false,
        isMobile: true,
        value: payload,
      };

      if (!/Mobi/i.test(payload)) {
        userAgentData.isMobile = false;
      }

      if (/ipad/i.test(payload)) {
        userAgentData.platform = 'ios';
        userAgentData.isIOS = true;
      } else if (/iphone/i.test(payload)) {
        userAgentData.platform = 'ios';
        userAgentData.isIOS = true;
      } else if (/android/i.test(payload)) {
        userAgentData.platform = 'android';
        userAgentData.isAndroid = true;
      } else {
        userAgentData.platform = 'other';
      }

      return {
        ...state,
        ...userAgentData,
      };
    }
    case types.STORE_BIXBY_FLAG: {
      return { ...state, isBixbyUser: payload };
    }
    default:
      return state;
  }
};

export default userAgentReducer;
