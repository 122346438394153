/**
 * has_news property indicates whether or not the league should have a {league}/news route
 */

export const home = [
  {
    slug: 'top_news',
    short_name: 'Top News',
    medium_name: 'Top News',
    resource_uri: '/topics/10113',
    resource_uris: {
      CA: '/topics/10112',
      US: '/topics/10113',
    },
    has_news: false,
    is_geo_targeted: true,
    sections: [],
  },
  // {
  //   slug: 'trending',
  //   short_name: 'Trending',
  //   medium_name: 'Trending',
  //   resource_uri: '/topics/9236',
  //   has_news: false,
  //   sections: [],
  // },
  // {
  //   slug: 'authors',
  //   short_name: 'Writers',
  //   medium_name: 'Writers',
  //   has_news: false,
  //   sections: [],
  // },
];

export const noLeague = {
  short_name: 'LEAGUES',
  medium_name: 'LEAGUES',
  sections: [],
};

export const defaultLeagues = [
  {
    slug: 'nfl',
    short_name: 'NFL',
    medium_name: 'NFL Football',
    resource_uri: '/football/leagues/1',
    has_news: true,
    sections: [
      {
        key: 'events',
        name: 'Scores',
        analytics_key: 'events',
        default: true,
      },
      {
        key: 'news',
        name: 'News',
        analytics_key: 'news',
        default: false,
      },
      {
        key: 'standings',
        name: 'Standings',
        analytics_key: 'standings',
        default: false,
      },
      {
        key: 'leaders',
        name: 'Leaders',
        analytics_key: 'leaders',
        default: false,
      },
    ],
  },
  {
    slug: 'nba',
    short_name: 'NBA',
    medium_name: 'NBA Basketball',
    resource_uri: '/basketball/leagues/2',
    has_news: true,
    sections: [
      {
        key: 'events',
        name: 'Scores',
        analytics_key: 'events',
        default: true,
      },
      {
        key: 'news',
        name: 'News',
        analytics_key: 'news',
        default: false,
      },
      {
        key: 'standings',
        name: 'Standings',
        analytics_key: 'standings',
        default: false,
      },
      {
        key: 'leaders',
        name: 'Leaders',
        analytics_key: 'leaders',
        default: false,
      },
    ],
  },
  {
    slug: 'mlb',
    short_name: 'MLB',
    medium_name: 'MLB Baseball',
    resource_uri: '/baseball/leagues/1',
    has_news: true,
    sections: [
      {
        key: 'events',
        name: 'Scores',
        analytics_key: 'events',
        default: false,
      },
      {
        key: 'news',
        name: 'News',
        analytics_key: 'news',
        default: true,
      },
      {
        key: 'standings',
        name: 'Standings',
        analytics_key: 'standings',
        default: false,
      },
      {
        key: 'leaders',
        name: 'Leaders',
        analytics_key: 'leaders',
        default: false,
      },
    ],
  },
  {
    slug: 'nhl',
    short_name: 'NHL',
    medium_name: 'NHL Hockey',
    resource_uri: '/hockey/leagues/1',
    has_news: true,
    sections: [
      {
        key: 'events',
        name: 'Scores',
        analytics_key: 'events',
        default: true,
      },
      {
        key: 'news',
        name: 'News',
        analytics_key: 'news',
        default: false,
      },
      {
        key: 'standings',
        name: 'Standings',
        analytics_key: 'standings',
        default: false,
      },
      {
        key: 'leaders',
        name: 'Leaders',
        analytics_key: 'leaders',
        default: false,
      },
    ],
  },
  {
    slug: 'epl',
    short_name: 'EPL',
    medium_name: 'EPL Soccer',
    resource_uri: '/soccer/leagues/4',
    has_news: true,
    sections: [
      {
        key: 'events',
        name: 'Fixtures',
        analytics_key: 'events',
        default: true,
      },
      {
        key: 'news',
        name: 'News',
        analytics_key: 'news',
        default: false,
      },
      {
        key: 'standings',
        name: 'Table',
        analytics_key: 'standings',
        default: false,
      },
      {
        key: 'leaders',
        name: 'Leaders',
        analytics_key: 'leaders',
        default: false,
      },
    ],
  },
];

export const allLeagues = home.concat(defaultLeagues);

export const menu = {
  home,
  leagues: defaultLeagues,
  all: allLeagues,
};

export default menu;
