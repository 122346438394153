import types from './types';

const setUtmSourceInitiated = () => ({ type: types.SET_UTM_SOURCE_INITIATED });

const setUtmSourceSucceeded = utmSource => ({
  type: types.SET_UTM_SOURCE_SUCCEEDED,
  payload: utmSource,
});

const setUtmSourceFailed = error => ({
  type: types.SET_UTM_SOURCE_FAILED,
  error: true,
  payload: error,
});

export default {
  setUtmSourceInitiated,
  setUtmSourceSucceeded,
  setUtmSourceFailed,
};
