import types from './types';

const setId = id => ({
  type: types.SET_ID,
  payload: id,
});

const setExperiments = experiments => ({
  type: types.SET_EXPERIMENTS,
  payload: experiments,
});

const setMobile = isMobile => ({
  type: types.SET_MOBILE,
  payload: isMobile,
});

const setInterstitialSeen = () => ({
  type: types.SET_INTERSTITIAL_SEEN,
  payload: true,
});

const setAlertSeen = () => ({
  type: types.SET_ALERT_SEEN,
  payload: true,
});

export default {
  setId,
  setExperiments,
  setMobile,
  setInterstitialSeen,
  setAlertSeen,
};
