import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { operations } from '~/state/pageData';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import last from 'lodash/last';
import classnames from 'classnames/bind';
import Link from '~/components/Link/Link';
import Styled from '~/components/Styled/Styled';
import Nav from '~/components/Nav/Nav';
import { noLeague } from '~/constants/Leagues';

import ShowBreakingNews from '~/components/ShowBreakingNews/ShowBreakingNews';
import ScheduleDateScrollMenu from '~/components/ScheduleDateScrollMenu/ScheduleDateScrollMenu';
import GetTheAppModal from '~/components/GetTheAppModal/GetTheAppModal';
import { injectIntl, intlShape } from 'react-intl';

import css from './Header.scss';

const cssContext = classnames.bind(css);

const getInitialSelectedDate = (params, schedule) => {
  if (params.group) {
    return params.group;
  }

  const currentEvent = get(schedule, 'current_group', last(schedule.current_season));

  if (currentEvent === null) {
    return {};
  }

  return currentEvent.id;
};

class Header extends Component {
  constructor(props) {
    super(props);

    props.setSelectedDate(getInitialSelectedDate(props.params, props.schedule));
  }

  render() {
    const eventsNavClass = cssContext(css.scheduleWrap, {
      hideOnMobile: this.props.hideEventsNavOnMobile,
    });
    let getAppUrl;
    if (this.props.bixbyUser) getAppUrl = 'https://thescore.app.link/0w1SwPRGnP';

    let scheduleData = [];

    if (this.props.schedule.current_season) {
      scheduleData = this.props.schedule.current_season;
    } else if (this.props.schedule.groups) {
      scheduleData = this.props.schedule.groups;
    }

    const renderMobile = !this.props.interstitialOpen;

    return (
      <div>
        <ShowBreakingNews />
        <div
          className={css.headerWrap}
          ref={node => {
            this.headerNode = node;
          }}
        >
          <header className={css.header}>
            <div className={css.headerContainer}>
              <div className={css.logoNavBox}>
                <Link className={css.logoInner} route="/">
                  <a aria-label="theScore site" className={css.logoWrapper}>
                    <div className={css.logo} />
                  </a>
                </Link>
                <div className={`${css.hideOnMobile} ${css.navContainer}`}>
                  {this.props.intl.locale === 'en' && <Nav renderMobile={false} />}
                </div>
              </div>
              <GetTheAppModal getAppUrl={getAppUrl} />
            </div>
          </header>
          <div className={css.hideOnDesktop}>
            {this.props.intl.locale === 'en' && (
              <Nav renderDesktop={false} renderMobile={renderMobile} />
            )}
          </div>
          {this.props.showEventsNav && scheduleData.length > 0 ? (
            <div className={eventsNavClass}>
              <ScheduleDateScrollMenu buttons items={scheduleData} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  currentLeague: PropTypes.shape({
    slug: PropTypes.string,
    short_name: PropTypes.string,
    medium_name: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
  }),
  schedule: PropTypes.object,
  params: PropTypes.object,
  showEventsNav: PropTypes.bool,
  hideEventsNavOnMobile: PropTypes.bool,
  intl: intlShape.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  bixbyUser: PropTypes.bool,
  interstitialOpen: PropTypes.bool,
};

Header.defaultProps = {
  currentLeague: noLeague,
  schedule: { current_season: [], current_group: {} },
  params: {},
  showEventsNav: false,
  hideEventsNavOnMobile: false,
  bixbyUser: false,
  interstitialOpen: true,
};

Header.displayName = 'Header';

const mapStateToProps = state => ({
  currentLeague: state.pageData.currentLeague,
  schedule: state.pageData.schedule,
  params: state.pageData.routeParams,
  bixbyUser: state.userAgent.isBixbyUser,
});

const mapDispatchToProps = dispatch => ({
  setSelectedDate: bindActionCreators(operations.setSelectedDate, dispatch),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Styled(Header, [css])));
