import React from 'react';
import PropTypes from 'prop-types';
import GetTheApp from '~/components/GetTheApp/GetTheApp';
import Cookies from 'js-cookie';
import isMobile from '~/util/isMobile';
import { connect } from 'react-redux';
import fromChatBot from '~/util/fromChatBot';
import NoSSR from 'react-no-ssr';

export class ShowBanner extends React.Component {
  componentDidMount() {
    if (this.props.section === 'events' && fromChatBot(this.props.utmSource)) {
      return;
    }

    if (this.props.adConfig.articleId === 1634703) {
      return;
    }

    const expiryTime = fromChatBot(this.props.utmSource) ? 7 : 1;
    if (!Cookies.get('interstitialSeen')) {
      Cookies.set('interstitialSeen', true, { expires: expiryTime });
    }
  }

  render() {
    if (this.props.section === 'events' && fromChatBot(this.props.utmSource)) {
      return null;
    }

    if (this.props.adConfig.articleId === 1634703) {
      return null;
    }

    if (typeof navigator !== 'undefined' && isMobile(navigator.userAgent)) {
      return <NoSSR>{!this.props.interstitialSeen && <GetTheApp isScrollDown />}</NoSSR>;
    }

    return null;
  }
}

ShowBanner.propTypes = {
  interstitialSeen: PropTypes.bool,
  section: PropTypes.string.isRequired,
  utmSource: PropTypes.string,
  adConfig: PropTypes.shape({
    articleId: PropTypes.number,
  }),
};

ShowBanner.defaultProps = {
  interstitialSeen: false,
  utmSource: '',
  adConfig: {
    articleId: null,
  },
};

const mapStateToProps = state => ({
  interstitialSeen: state.abTest.data.interstitialSeen,
  section: state.pageData.currentSection,
  utmSource: state.utmSource.data,
  adConfig: state.pageData.adConfig,
});

export default connect(mapStateToProps)(ShowBanner);
