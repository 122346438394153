import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Link from '~/components/Link/Link';
import StyledComponent from '~/components/Styled/Styled';
import getLeaguesLive from '~/util/getLeaguesLive';
import LiveNowChip from '~/components/LiveNowChip/LiveNowChip';
import logEvent from '~/util/analytics';
import { noLeague } from '~/constants/Leagues';
import { connect } from 'react-redux';
import slice from 'lodash/slice';
import FocusTrap from 'focus-trap-react';

import css from './MobileMenu.scss';

class MobileMenu extends React.Component {
  constructor() {
    super();

    this.state = {
      showDropdownMenu: false,
      showMoreLeaguesTooltip: false,
      leagues: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleLeaguesLive = this.handleLeaguesLive.bind(this);
    this.listenForViewChange = this.listenForViewChange.bind(this);
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleEscapeKey);
    window.addEventListener('visibilitychange', this.listenForViewChange);

    this.handleLeaguesLive();
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleEscapeKey);
    window.removeEventListener('visibilitychange', this.listenForViewChange);
  };

  listenForViewChange() {
    if (document.visibilityState === 'visible') {
      this.handleLeaguesLive();
    }
  }

  async handleLeaguesLive() {
    const leaguesLiveRes = await getLeaguesLive();
    const leaguesLive = get(leaguesLiveRes, 'json', []);
    let leaguesToShow = [];

    leaguesToShow = this.props.menu.show.reduce((acc, elem) => {
      const leagueToUpdate = leaguesLive.find(leagueObj => leagueObj.league === elem.slug) || null;

      acc.push({
        ...elem,
        events_in_progress: (leagueToUpdate && leagueToUpdate.in_progress_event_count > 0) || false,
      });

      return acc;
    }, []);

    this.setState({
      leagues: leaguesToShow,
    });
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleClick();
    }
  };

  handleEscapeKey = event => {
    if (event.key === 'Escape') {
      if (this.state.showDropdownMenu) {
        this.closeMenu();
      }
    }
  };

  handleClick() {
    if (this.state.showDropdownMenu) {
      logEvent('site_menu', {
        type: 'close',
      });
      document.body.style.overflow = '';
    } else {
      logEvent('site_menu', {
        type: 'open',
      });
      document.body.style.overflow = 'hidden';
    }
    this.setState({
      showDropdownMenu: !this.state.showDropdownMenu,
      showMoreLeaguesTooltip: false,
    });
  }

  closeMenu = () => {
    logEvent('site_menu', {
      type: 'close',
    });

    document.body.style.overflow = '';

    this.setState({
      showDropdownMenu: false,
      showMoreLeaguesTooltip: false,
    });
  };

  handleMenuItemClick(league) {
    logEvent('site_menu', {
      type: 'navigation',
      league,
    });
    document.body.style.overflow = '';
    this.setState({ showDropdownMenu: false });
  }

  renderHome() {
    return (
      <div>
        <div className={css.menuHeader}>
          Home
          <button
            onClick={this.handleClick}
            onKeyDown={this.handleKeyDown}
            className={`${css.leagueIcon} ${css.closeButton}`}
            role="button"
            tabIndex="0"
            aria-label="Close Main Menu"
            aria-expanded="true"
          />
        </div>
        {this.props.menu.home.map(item => (
          <Link
            route={item.has_news ? `/${item.slug}/news` : `/${item.slug}`}
            onClick={e => {
              e.stopPropagation();
              this.handleMenuItemClick(item.slug);
            }}
            key={item.slug}
          >
            <a className={css.link}>
              <div className={css.menuElement}>
                {item.medium_name.length < 20 ? item.medium_name : item.short_name}
              </div>
            </a>
          </Link>
        ))}
      </div>
    );
  }

  renderLeagues() {
    return (
      <div>
        <div className={css.menuHeader}>All Leagues</div>
        <div className={css.leagueElement}>
          {this.state.leagues.map(item => (
            <div key={`column-${item.slug}`}>
              {item.slug === 'worldcup' ? null : (
                <div key={item.slug}>
                  <Link
                    route={item.has_news ? `/${item.slug}/news` : `/${item.slug}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.handleMenuItemClick(item.slug);
                    }}
                  >
                    <a className={css.link}>
                      <div
                        className={`${css.menuElement}
                        ${
                          item.short_name === this.props.currentLeague.short_name
                            ? css.selected
                            : ''
                        }`}
                      >
                        <span
                          className={`${css[item.sport_name] ? css[item.sport_name] : ''}
                          ${css.leagueIcon}`}
                        />
                        <div className={css.menuElementInnerContainer}>
                          <span>{item.medium_name}</span>
                          {item.events_in_progress ? <LiveNowChip /> : null}
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderSectionDropdown(league) {
    const menuName = `show${league.short_name.toUpperCase()}Menu`;

    return (
      <div
        className={`${css.leagueMenu} ${this.state[menuName] ? css.hoverMenu : ''}`}
        key={league.short_name}
      >
        <Link
          key={`${league.slug}Nav`}
          route={`/${league.slug}/news`}
          onClick={e => {
            e.stopPropagation();
            this.handleMenuItemClick(league.short_name);
          }}
        >
          <a className={css.link}>
            <div className={`${css.menuButton} ${css.leagueHeader}`}>
              {league.short_name.toUpperCase()}
            </div>
          </a>
        </Link>
      </div>
    );
  }

  render() {
    const { showDropdownMenu } = this.state;
    const league = this.props.currentLeague.short_name || 'LEAGUES';
    const topNews = league.toLowerCase() === 'top news' || league.toLowerCase() === 'trending';

    return (
      <div className={css.flexWrapper}>
        <div
          role="presentation"
          onClick={this.closeMenu}
          className={`${css.menuUnderlay} ${this.state.showDropdownMenu ? css.selected : ''}`}
        />
        <div className={`${css.slideMenu} ${this.state.showDropdownMenu ? css.selected : ''}`}>
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
            }}
            active={showDropdownMenu}
          >
            <div id="mobile-menu" className={css.menuWrapper}>
              {this.renderHome()}
              {this.renderLeagues()}
              <div className={css.menuHeader}>Follow Us</div>
              <a href="https://facebook.com/theScore" className={css.link}>
                <div className={css.menuElement}>Facebook </div>
              </a>
              <a href="https://twitter.com/theScore" className={css.link}>
                <div className={css.menuElement}>Twitter</div>
              </a>
              <a href="https://instagram.com/thescore" className={css.link}>
                <div className={css.menuElement}>Instagram</div>
              </a>
            </div>
          </FocusTrap>
        </div>
        <div
          className={css.mainMenu}
          onKeyDown={this.handleKeyDown}
          onClick={this.handleClick}
          role="button"
          aria-label="Open Main menu"
          aria-expanded="false"
          tabIndex={0}
        >
          <div className={css.menuButton}>
            <span className={`${css.hamburger} ${css.leagueIcon}`} />
          </div>
        </div>
        <div className={`${css.menuButton} ${css.leagueHeader} ${css.divider}`}>
          {league.toUpperCase()}
        </div>
        {topNews && slice(this.props.menu.show, 0, 4).map(elem => this.renderSectionDropdown(elem))}
      </div>
    );
  }
}

const leagueShape = PropTypes.shape({
  slug: PropTypes.string,
  short_name: PropTypes.string,
  medium_name: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
});

MobileMenu.propTypes = {
  currentLeague: leagueShape,
  menu: PropTypes.shape({
    home: PropTypes.arrayOf(leagueShape),
    all: PropTypes.arrayOf(leagueShape),
    show: PropTypes.arrayOf(leagueShape),
    more: PropTypes.arrayOf(leagueShape),
    spotlights: PropTypes.array,
  }).isRequired,
  isMobile: PropTypes.bool,
};

MobileMenu.defaultProps = {
  currentLeague: noLeague,
  isMobile: false,
};

MobileMenu.displayName = 'MobileMenu';

const mapStateToProps = state => ({
  isMobile: state.userAgent.isMobile,
});

export default connect(mapStateToProps)(StyledComponent(MobileMenu, [css]));
