import React from 'react';

import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import StyledComponent from '~/components/Styled/Styled';

import css from './Button.scss';

const cssContext = classnames.bind(css);

class Button extends React.PureComponent {
  render() {
    const { width, height, borderRadius, fontSize, hasGradient } = this.props;
    const containerClasses = cssContext(css.button, {
      gradientBackground: hasGradient,
      solidBackground: !hasGradient,
    });
    return (
      <div
        className={containerClasses}
        style={{ width, height, borderRadius, lineHeight: height, fontSize }}
      >
        {this.props.copy}
      </div>
    );
  }
}

Button.propTypes = {
  copy: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  fontSize: PropTypes.string,
  hasGradient: PropTypes.bool,
};

Button.defaultProps = {
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  fontSize: '14px',
  hasGradient: false,
};

Button.displayName = 'Button';

export default StyledComponent(Button, [css]);
