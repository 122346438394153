import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';

import css from './LiveNowChip.scss';

/* eslint-disable react/prefer-stateless-function */

class LiveNowChip extends React.PureComponent {
  render() {
    const { siteMenu = false } = this.props;

    return (
      <div className={`${css.liveNowContainer} ${siteMenu ? css.siteMenu : ''}`}>
        <div className={css.liveNowText}>LIVE</div>
      </div>
    );
  }
}

LiveNowChip.propTypes = {
  siteMenu: PropTypes.bool,
};

LiveNowChip.defaultProps = {
  siteMenu: false,
};

LiveNowChip.displayName = 'LiveNowChip';

export default StyledComponent(LiveNowChip, [css]);
