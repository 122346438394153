exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BreakingNewsAlert__container--3Chp7 {\n  width: 100%;\n}\n\n.BreakingNewsAlert__root--2QpVe {\n  margin: 8px;\n  padding: 0 10px 10px;\n  height: 100%;\n  border-radius: 1.8px;\n  background-color: #f9f9f9;\n  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);\n  border-style: solid;\n  border-width: 0.9px;\n  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));\n  border-image-slice: 1;\n  display: flex;\n}\n\n.BreakingNewsAlert__rightColumn--1OnSF {\n  width: 100%;\n}\n\n.BreakingNewsAlert__description--20Cap {\n  font-size: 12px;\n  font-weight: 300;\n  line-height: 1.42;\n  text-align: left;\n  color: #0c0d0e;\n  opacity: 0.65;\n}\n\n.BreakingNewsAlert__appName--13H4p {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 1.57;\n  letter-spacing: 0.4px;\n  text-align: left;\n  color: rgba(0, 0, 0, 0.87);\n  display: inline-block;\n}\n\n.BreakingNewsAlert__header--19tuh {\n  margin: 10px 0 0;\n}\n\n.BreakingNewsAlert__appIcon--1dvC_ {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  background-image: url(\"/static/vectors/thescore-icon-android.svg\");\n  background-size: contain;\n  display: block;\n  margin: 10px 10px 0 0;\n}\n\n.BreakingNewsAlert__closeButton--2qKIC {\n  color: #000;\n  position: absolute;\n  right: 18px;\n  top: 10px;\n  z-index: 2;\n  width: 15px;\n  height: 15px;\n  background: url(\"/static/vectors/close-dark.svg\") no-repeat 0 0/contain;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"container": "BreakingNewsAlert__container--3Chp7",
	"root": "BreakingNewsAlert__root--2QpVe",
	"rightColumn": "BreakingNewsAlert__rightColumn--1OnSF",
	"description": "BreakingNewsAlert__description--20Cap",
	"appName": "BreakingNewsAlert__appName--13H4p",
	"header": "BreakingNewsAlert__header--19tuh",
	"appIcon": "BreakingNewsAlert__appIcon--1dvC_",
	"closeButton": "BreakingNewsAlert__closeButton--2qKIC"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };