import PropTypes from 'prop-types';
import React from 'react';
import StyledComponent from '~/components/Styled/Styled';
import PerfectScrollbar from 'perfect-scrollbar';

import css from '~/components/ScrollMenu/ScrollMenu.scss';

class ScrollMenu extends React.Component {
  constructor(props) {
    super(props);

    this.hasScrolled = false;
    this.scrollMenu = React.createRef();
    this.start = 0;
    this.change = 200; // default, should be set later
    this.currentTime = 0;
    this.increment = 20;
    this.duration = 200;

    this.scrollTo = this.scrollTo.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);

    this.ps = null;
  }

  componentDidMount() {
    this.ps = new PerfectScrollbar(this.scrollMenu.current, {
      maxScrollbarLength: 75,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.offset !== this.props.offset) {
      this.scrollTo(newProps.offset);
    }
  }

  componentWillUnmount() {
    if (this.ps) {
      this.ps.destroy();
      this.ps = null;
    }
  }

  scrollTo(offset, cb) {
    this.start = this.scrollMenu.current.scrollLeft;
    this.currentTime = 0;
    this.change = offset - this.scrollMenu.current.scrollLeft;
    this.animateScroll(cb);
  }

  scrollRight() {
    this.start = this.scrollMenu.current.scrollLeft;
    this.currentTime = 0;
    this.change = this.scrollMenu.current.offsetWidth / 2;
    this.animateScroll();
  }

  scrollLeft() {
    this.start = this.scrollMenu.current.scrollLeft;
    this.currentTime = 0;
    this.change = -this.scrollMenu.current.offsetWidth / 2;
    this.animateScroll();
  }

  // Scroll functions, adapted from https://gist.github.com/andjosh/6764939:

  easeInOutQuad(time) {
    let newTime = time / this.duration * 2;
    if (newTime < 1) return this.change / 2 * newTime * newTime + this.start;
    newTime -= 1;
    return -(this.change / 2) * (newTime * (newTime - 2) - 1) + this.start;
  }

  animateScroll(cb) {
    if (!this.scrollMenu.current) {
      return;
    }
    this.currentTime += this.increment;
    this.scrollMenu.current.scrollLeft = this.easeInOutQuad(this.currentTime);
    if (this.currentTime < this.duration) {
      setTimeout(() => this.animateScroll(cb), this.increment);
    } else {
      setTimeout(cb, this.increment);
    }
  }

  render() {
    const { children, height, navMenu } = this.props;

    return (
      <div className={`${css.scrollMenu} ${navMenu ? css.navMenu : null}`} style={{ height }}>
        {this.props.buttons && (
          <div className={`${css.scrollButton} ${css.scrollLeft}`} onClick={this.scrollLeft} />
        )}
        <div className={css.headingScrollWrapper} style={{ height }}>
          <div className={css.headingScroll} ref={this.scrollMenu}>
            {children}
          </div>
        </div>
        {this.props.buttons && (
          <div className={`${css.scrollButton} ${css.scrollRight}`} onClick={this.scrollRight} />
        )}
      </div>
    );
  }
}

ScrollMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  offset: PropTypes.number,
  buttons: PropTypes.bool,
  height: PropTypes.string,
  navMenu: PropTypes.bool,
};

ScrollMenu.defaultProps = {
  offset: 0,
  buttons: false,
  height: '100%',
  navMenu: false,
};

ScrollMenu.displayName = 'ScrollMenu';

export default StyledComponent(ScrollMenu, [css]);
