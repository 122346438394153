import types from './types';

const teamReducer = (state = { isLoading: false }, { type, payload }) => {
  switch (type) {
    case types.FETCH_TEAM_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_TEAM_SUCCEEDED: {
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    }

    case types.FETCH_TEAM_FAILED: {
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default teamReducer;
