import types from './types';

const storeUserAgent = userAgent => ({
  type: types.STORE_USER_AGENT,
  payload: userAgent,
});

const storeBixbyFlag = bixbyUser => ({
  type: types.STORE_BIXBY_FLAG,
  payload: bixbyUser,
});

export default {
  storeUserAgent,
  storeBixbyFlag,
};
