/* global API_URL */

import { getDefaultConference } from '~/util/getConferences';

const localTimezoneOffset = () => {
  const now = new Date();
  const maxOffset = 86399;
  const minOffset = -86399;
  const utcOffset = now.getTimezoneOffset() * -60;

  // We are limiting the offset to by within the timeframe of
  // +/- 24 hours. This prevents the odd occurences when clients
  // request schedule using very large timeframes that the api
  // may not support. 86399 is minutes of a day - 1
  if (utcOffset > minOffset && utcOffset < maxOffset) {
    return utcOffset;
  }

  return 0;
};

const sanitizeConference = async (conference, leagueSlug) => {
  if (conference) {
    return conference;
  }

  return getDefaultConference(leagueSlug);
};

const getSchedule = async (leagueSlug, conference = null) => {
  const defaultOptions = `utc_offset=${localTimezoneOffset()}`;

  let queryParams = defaultOptions;

  if (leagueSlug === 'ncaab' || leagueSlug === 'ncaaf' || leagueSlug === 'wcbk') {
    const conferenceResult = await sanitizeConference(conference, leagueSlug);
    const options = [defaultOptions, `conference=${encodeURI(conferenceResult)}`];

    queryParams = options.join('&');
  }

  return fetch(`${API_URL}/${leagueSlug}/schedule?${queryParams}`).then(response =>
    response.json()
  );
};

export default getSchedule;
