import getTeam from '~/util/getTeamData';
import actions from './actions';

const setTeam = (leagueSlug, teamId) => async (dispatch, getState) => {
  const currentTeam = getState().teamData.data;
  if (currentTeam && currentTeam.id === teamId) return Promise.resolve(currentTeam);

  dispatch(actions.setTeamInitiated());
  return getTeam(leagueSlug, teamId)
    .then(team => {
      dispatch(actions.setTeamSucceeded(team.json));
      return team.json;
    })
    .catch(err => {
      dispatch(actions.setTeamFailed(err));
      return { api_uri: '/errors/events' };
    });
};

export default {
  setTeam,
};
