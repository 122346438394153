/* global RICH_CONTENT_FEED_URL */
/* global CMS_API_URL */

import uniq from 'lodash/uniq';
import find from 'lodash/find';
import { CURATED_CARD_TYPES } from '~/constants/ContentCardTypes';
import { defaultLeagues } from '~/constants/Leagues';
import fetch from '~/util/fetch';

const bigFourNames = ['nfl', 'nhl', 'nba', 'mlb'];
const bigFourUriString = bigFourNames
  .map(name => find(defaultLeagues, league => league.slug === name).resource_uri)
  .join(',');

export const getMatchupArticle = async event => {
  try {
    const articleType = event.event_status === 'final' ? 'recap' : 'preview';
    const parameters = [
      'embed_ids=true',
      `q[resource_tags_uri_eq]=${event.resource_uri}`,
      `q[category_eq]=${articleType}`,
    ];
    const endpoint = `${CMS_API_URL}/news`;
    const res = await fetch(`${endpoint}?${parameters.join('&')}`);
    if (res.status !== 200) {
      throw Error('Request failed');
    }
    const json = await res.json();
    return json.articles[0];
  } catch (errors) {
    return {};
  }
};

export const getAuthorArticles = async authorSlug => {
  try {
    const params = ['embed_ids=true', 'all=true', `q[author_slug_eq]=${authorSlug}`];
    const endpoint = `${CMS_API_URL}/news/all`;
    const res = await fetch(`${endpoint}?${params.join('&')}`);
    const json = await res.json();

    return json;
  } catch (errors) {
    return {};
  }
};

export const getArticleCard = async (articleId, storedContent, locale = null) => {
  let json = {};
  const storedArticle = find(storedContent, ['data.id', articleId]);

  try {
    if (storedArticle) {
      json = {
        article: storedArticle.data,
        games: [],
      };
    } else {
      const url = `${CMS_API_URL}/news/${articleId}`;

      /*
       * If there's a locale present, use the locale url and then fallback to the english case if it fails,
       * Otherwise we just use the english case
       */

      const res = locale
        ? await fetch(`${url}?language=${locale}`).catch(() => fetch(url))
        : await fetch(url);

      if (res.status !== 200) {
        throw Error('Request failed');
      }
      json = await res.json();
    }
    return json;
  } catch (errors) {
    json.article = {
      byline: '',
      title: '',
      id: null,
      type: null,
      tags: [],
    };
    return json;
  }
};

export const getContentCard = async (contentId, storedContent) => {
  let json = {};

  try {
    if (storedContent[contentId]) {
      json = {
        content_card: storedContent[contentId],
        games: [],
      };
    } else {
      const res = await fetch(`${RICH_CONTENT_FEED_URL}/content_cards/${contentId}.json`);
      if (res.status !== 200) {
        throw Error('Requested failed');
      }
      json = await res.json();
    }
    return json;
  } catch (errors) {
    json.content_card = {
      id: null,
      type: null,
      tags: [],
    };
    return json;
  }
};

export const getRelatedContent = async (
  relatedUris = null,
  before = null,
  userIP = null,
  excludePinned = null,
  leagueSlug = null,
  cardTypes = CURATED_CARD_TYPES
) => {
  try {
    const endpoint = `${RICH_CONTENT_FEED_URL}/content_cards`;
    const parameters = [`resource_uris=${relatedUris || bigFourUriString}`, 'limit=15'];

    if (before) {
      parameters.push(`before=${before}`);
    }
    if (excludePinned) {
      parameters.push('exclude_pinned=true');
    }

    if ((leagueSlug && leagueSlug !== 'trending') || !relatedUris) {
      parameters.push(`card_types=${cardTypes.join(',')}`);
    }

    const related = await fetch(`${endpoint}?${parameters.join('&')}`);
    const relatedJson = await related.json();
    const newBefore = relatedJson.meta.cursors.before;
    const relatedContentList = uniq(relatedJson.content_cards);

    return {
      relatedContentList,
      newBefore,
    };
  } catch (errors) {
    return { relatedContentList: [], newBefore: null };
  }
};

export const getDiscoverContent = async () => {
  try {
    const discover = await fetch(
      `${RICH_CONTENT_FEED_URL}/content_cards?resource_uris=/topics/9236`
    );
    const discoverJson = await discover.json();
    const discoverContentlist = uniq(discoverJson.content_cards).map(tag => {
      const images = tag.data.images || tag.data.thumbnails || {};
      const image = images.thumb || images.thumbnail || {};
      return {
        caption: tag.caption,
        contentId: tag.id.toString(),
        imageUrl: image.url || null,
      };
    });

    return discoverContentlist;
  } catch (errors) {
    return [];
  }
};

const getRelatedUris = (league, countryCode = null) => {
  if (league.is_geo_targeted) {
    return league.resource_uris[countryCode]
      ? league.resource_uris[countryCode]
      : league.resource_uri;
  }

  return league.resource_uri;
};

export const getRelatedContentFromLeague = async (
  league,
  contentId,
  before = null,
  countryCode = null,
  excludePinned = null
) => {
  const relatedUris = getRelatedUris(league, countryCode);
  const relatedContent = await getRelatedContent(
    relatedUris,
    before,
    null,
    excludePinned,
    league.slug
  );

  const relatedContentList = relatedContent.relatedContentList.filter(tag => tag.id !== contentId);
  const newBefore = relatedContent.newBefore;
  return { relatedContentList, newBefore };
};

export const getPinnedContent = async resourceUris => {
  try {
    const pinned = await fetch(
      `${RICH_CONTENT_FEED_URL}/content_cards/pinned?resource_uris=${resourceUris}`
    );
    const pinnedJson = await pinned.json();

    return pinnedJson;
  } catch (errors) {
    return { content_cards: [] };
  }
};

export const getRiverData = async (
  league,
  contentId = null,
  before = null,
  countryCode = null,
  excludePinned = null
) => {
  const pinnedContent = getPinnedContent(getRelatedUris(league, countryCode));
  const relatedContent = getRelatedContentFromLeague(
    league,
    contentId,
    before,
    countryCode,
    excludePinned
  );

  return {
    pinnedContent: await pinnedContent,
    relatedContent: await relatedContent,
  };
};
