import React from 'react';
import NoSSR from 'react-no-ssr';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';

import operations from '~/state/breakingNews/operations';
import logEvent from '~/util/analytics';
import BreakingNewsExperiment from '~/experiments/BreakingNews/BreakingNewsExperiment';
import BreakingNewsAlert from '~/components/BreakingNewsAlert/BreakingNewsAlert';
import StyledComponent from '~/components/Styled/Styled';

import css from '~/components/ShowBreakingNews/ShowBreakingNews.scss';

const BREAKING_NEWS_ALERT_DELAY = 10000;

type ShowBreakingNewsProps = {
  userId: string;
  breakingNews: any;
  alertSeen: boolean;
  league: any;
  isMobile: boolean;
  setBreakingNews: any;
};

type ShowBreakingNewsState = {
  show: boolean;
};

class ShowBreakingNews extends React.Component<ShowBreakingNewsProps, ShowBreakingNewsState> {
  static displayName = 'ShowBreakingNews';

  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  componentDidMount() {
    if (!this.props.alertSeen && this.props.isMobile) {
      this.props.setBreakingNews(this.props.league);
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.breakingNews.id !== this.props.breakingNews.id &&
      !Cookies.get('alertSeen') &&
      this.props.isMobile
    ) {
      setTimeout(() => {
        // @ts-ignore
        if (this.exp.get('show_breaking_news')) {
          logEvent('alert_shown', {
            type: 'breaking_news',
            breaking_news_id: this.props.breakingNews.id,
            league: this.props.league.slug,
          });
          Cookies.set('alertSeen', true, { expires: 1 });
          this.setState({ show: true });
        }
      }, BREAKING_NEWS_ALERT_DELAY);
    }
  }

  // @ts-ignore
  exp = new BreakingNewsExperiment({ userId: this.props.userId });

  render() {
    if (this.props.isMobile) {
      // @ts-ignore
      this.exp.active = true;
      return (
        <div>
          {!this.props.alertSeen &&
            this.props.breakingNews.id &&
            this.state.show && (
              <NoSSR>
                <div className={css.root}>
                  <BreakingNewsAlert article={this.props.breakingNews} />
                </div>
              </NoSSR>
            )}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  userId: state.abTest.data.userId,
  breakingNews: state.breakingNews.breakingNews,
  alertSeen: state.abTest.data.alertSeen,
  league: state.pageData.currentLeague,
  isMobile: state.userAgent.isMobile,
});

const mapDispatchToProps = dispatch => ({
  setBreakingNews: bindActionCreators(operations.setBreakingNews, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  StyledComponent(ShowBreakingNews, [css])
);
