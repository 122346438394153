import 'isomorphic-fetch';
import includes from 'lodash/includes';
import get from 'lodash/get';
import validAPIResult from '~/util/validAPIResult';
import fetchVCR from '~/util/fetchVCR';

/* global API_URL */

const formatAPIResult = json => {
  if (validAPIResult(json)) {
    return { json, statusCode: 200 };
  }

  return { json, statusCode: get(json, 'error.code', 500) };
};

export const handleRequestFailure = error => {
  const newError = new Error('NETWORK_ERROR');
  newError.ok = false;
  newError.statusText = error.message;
  throw newError;
};

export const handleResponse = result => {
  const statusCode = result.status;
  let problem = null;
  let newError = null;
  if (statusCode >= 500) {
    problem = 'Server Error';
    newError = new Error(problem);
    throw newError;
  } else if (statusCode >= 400) {
    problem = 'Client Error';
    newError = new Error(problem);
    throw newError;
  }

  if (includes(result.url, API_URL)) {
    return result
      .json()
      .then(json => formatAPIResult(json))
      .catch(() => ({ json: {}, statusCode: 500 }));
  }

  return result
    .json()
    .then(json =>
      Promise.resolve({
        json,
        problem,
        statusCode,
      })
    )
    .catch(() => ({
      json: {},
      problem,
      statusCode,
    }));
};

const customFetch = async (uri, options) => {
  if (!options) {
    return process.env.VCR_MODE ? fetchVCR(uri) : fetch(uri);
  }
  return process.env.VCR_MODE ? fetchVCR(uri, options) : fetch(uri, options);
};

export default customFetch;
