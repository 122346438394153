exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LiveNowChip__liveNowContainer--3rgh8 {\n  align-items: center;\n  border-radius: 4px;\n  background-color: #1e1f21;\n  display: flex;\n  justify-content: center;\n  padding: 4px 8px;\n}\n\n.LiveNowChip__liveNowText--2PECg {\n  color: #18e865;\n  flex: 1;\n  font-size: 10px;\n  font-weight: 700;\n  letter-spacing: 0.05em;\n  text-transform: uppercase;\n}\n\n.LiveNowChip__siteMenu--3v4HR {\n  padding: 0 4px;\n}", ""]);

// exports
exports.locals = {
	"liveNowContainer": "LiveNowChip__liveNowContainer--3rgh8",
	"liveNowText": "LiveNowChip__liveNowText--2PECg",
	"siteMenu": "LiveNowChip__siteMenu--3v4HR"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };