import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import css from './DefaultInterstitial.scss';

const DefaultInterstitial = props => (
  <div>
    <div className={css.flexContainer}>
      <div className={css.logo} />
      <p className={css.caption}>{props.copyText.title}</p>
      <div className={css.imageContainer}>
        <img
          className={css.ctaPhoto}
          src="/static/images/cta-phoneicon-2022.png"
          alt="theScore App screen"
        />
        <ul className={css.list}>
          <li className={css.listElement}>
            <span>{props.copyText.bulletPoint1}</span>
          </li>
          <li className={css.listElement}>
            <span>{props.copyText.bulletPoint2}</span>
          </li>
          <li className={css.listElement}>
            <span>{props.copyText.bulletPoint3}</span>
          </li>
        </ul>
      </div>
    </div>
    <a
      href={
        props.bixbyUser
          ? `https://thescore.app.link/OT2KiPMGnP`
          : `https://thescore.app.link/4IiNl704fL?campaign=basedesign${props.buttonText.name}`
      }
    >
      <button
        className={css.downloadButton}
        onClick={() => {
          props.handleGetTheAppClick('stubpagedownloadCTA', props.experiments);
        }}
      >
        {props.buttonText.label}
      </button>
    </a>
    <div className={css.appStoreButtons}>
      <a
        className={css.appStoreButton}
        href={`https://thescore.app.link/zazhQYiCYG?campaign=basedesign${props.buttonText.name}`}
        onClick={() => {
          props.handleGetTheAppClick('Get_theScoreAppiOS', props.experiments);
        }}
      >
        <img
          src="/static/images/app-store.png"
          srcSet="/static/images/app-store.png 1x, /static/images/app-store@2x.png 2x"
          alt="Get in from the App Store"
        />
      </a>
      <a
        className={css.playStoreButton}
        href={`https://thescore.app.link/TegVgYtCYG?campaign=basedesign${props.buttonText.name}`}
        onClick={() => {
          props.handleGetTheAppClick('Get_theScoreAppAndroid', props.experiments);
        }}
      >
        <img
          src="/static/images/play-store.png"
          srcSet="/static/images/play-store.png 1x, /static/images/play-store@2x.png 2x"
          alt="Get it on Google Play"
        />
      </a>
    </div>
    <button onClick={props.scrollDown}>
      <p className={css.moreContentText}>Scroll down to see full content</p>
      <p className={css.moreContentChevron}>&#9662;</p>
    </button>
  </div>
);

DefaultInterstitial.propTypes = {
  handleGetTheAppClick: PropTypes.func,
  scrollDown: PropTypes.func,
  experiments: PropTypes.array,
  copyText: PropTypes.shape({
    title: PropTypes.string.isRequired,
    bulletPoint1: PropTypes.string.isRequired,
    bulletPoint2: PropTypes.string.isRequired,
    bulletPoint3: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  buttonText: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  bixbyUser: PropTypes.bool,
};

DefaultInterstitial.defaultProps = {
  handleGetTheAppClick: () => {},
  scrollDown: () => {},
  experiments: [],
  bixbyUser: false,
};

DefaultInterstitial.displayName = 'DefaultInterstitial';

export default StyledComponent(DefaultInterstitial, [css]);
