import types from './types';

const initialState = {
  interstitialDisabled: false,
};

const interstitialReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_INTERSTITIAL_DISABLED: {
      return {
        ...state,
        interstitialDisabled: payload,
      };
    }
    default:
      return state;
  }
};

export default interstitialReducer;
