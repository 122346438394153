import types from './types';

const conferencesReducer = (state: any = { isLoading: false }, { type, payload, leagueSlug }) => {
  switch (type) {
    case types.FETCH_CONFERENCES_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_CONFERENCES_SUCCEEDED: {
      return {
        ...state,
        cache: { ...state.cache, [leagueSlug]: payload },
        data: payload,
        isLoading: false,
      };
    }

    case types.FETCH_CONFERENCES_FAILED: {
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default conferencesReducer;
