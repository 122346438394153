import types from './types';

const initialState = { isLoading: false };

const utmReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case types.SET_UTM_SOURCE_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.SET_UTM_SOURCE_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
        data: payload,
      };
    }

    case types.SET_UTM_SOURCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        data: null,
      };
    }

    default:
      return state;
  }
};

export default utmReducer;
