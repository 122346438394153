import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Link from '~/components/Link/Link';
import StyledComponent from '~/components/Styled/Styled';
import ScrollMenuElement from '~/components/ScrollMenuElement/ScrollMenuElement';
import logEvent from '~/util/analytics';
import LiveNowChip from '~/components/LiveNowChip/LiveNowChip';
import getLeaguesLive from '~/util/getLeaguesLive';
import { noLeague } from '~/constants/Leagues';
import { connect } from 'react-redux';
import slice from 'lodash/slice';
import FocusTrap from 'focus-trap-react';

import css from './DesktopMenu.scss';

const groupLeaguesIntoColumns = leagues => {
  const itemsPerColumn = 12;

  const orderedLeagues = leagues.slice(); // Need a copy of the array

  const columns = orderedLeagues.reduce((acc, curr, i) => {
    acc[i] = orderedLeagues.splice(0, itemsPerColumn);
    acc[i].columnGroup = true;
    return acc;
  }, []);

  // Put the remaining items into columns, left to right;
  if (orderedLeagues.length > 0) {
    orderedLeagues.forEach((league, i) => columns[i].push(league));
  }

  return columns;
};

const slope = (a, b) => (b.y - a.y) / (b.x - a.x);

const getLeagueUrl = leagueSlug => {
  if (!leagueSlug || leagueSlug === 'top_news') return '/';
  if (leagueSlug === 'trending') return '/trending';
  return `/${leagueSlug}/news`;
};

class DesktopMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdownMenu: false,
      leagues: [],
    };

    this.mouseLocations = [];

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleLeaguesLive = this.handleLeaguesLive.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.possiblyClose = this.possiblyClose.bind(this);
    this.handleMenuProps = this.handleMenuProps.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.listenForViewChange = this.listenForViewChange.bind(this);
    this.dropdownMenu = React.createRef();
    this.dropdownMenuKeyList = ['showDropdownMenu'];
  }

  componentDidMount = async () => {
    window.addEventListener('keydown', this.handleEscapeKey);
    window.addEventListener('visibilitychange', this.listenForViewChange);

    this.handleMenuProps();
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleEscapeKey);
    window.removeEventListener('visibilitychange', this.listenForViewChange);
  };

  async handleMenuProps() {
    const leaguesWithLiveEvents = await this.handleLeaguesLive();

    this.setState({ leagues: groupLeaguesIntoColumns(leaguesWithLiveEvents) });
  }

  async handleLeaguesLive() {
    const leaguesLiveRes = await getLeaguesLive();
    const leaguesLive = get(leaguesLiveRes, 'json', []);

    return this.props.menu.show.reduce((acc, elem) => {
      const leagueToUpdate = leaguesLive.find(leagueObj => leagueObj.league === elem.slug) || null;

      acc.push({
        ...elem,
        events_in_progress: (leagueToUpdate && leagueToUpdate.in_progress_event_count > 0) || false,
      });

      return acc;
    }, []);
  }

  listenForViewChange() {
    if (document.visibilityState === 'visible') {
      this.handleMenuProps();
    }
  }

  handleEscapeKey = event => {
    if (event.key === 'Escape') {
      if (this.state.showDropdownMenu) {
        this.handleMouseLeave('showDropdownMenu');
      }
    }
  };

  handleMouseMove(e) {
    const currentPosition = { x: e.clientX, y: e.clientY };
    this.mouseLocations.push(currentPosition);
    if (this.mouseLocations.length > 2) this.mouseLocations.shift();
  }

  handleMouseOver(menuName, league = null) {
    clearTimeout(this.menuTimer);
    const menuState = {};
    const eventParams = { type: 'open' };

    this.dropdownMenuKeyList.forEach(elem => {
      if (menuName !== elem) {
        menuState[elem] = false;
      }
    });
    menuState[menuName] = true;

    if (this.state[menuName]) return;

    if (menuName !== 'showDropdownMenu' && league) {
      eventParams.league_name = league;
    }

    logEvent(menuName === 'showDropdownMenu' ? 'site_menu' : 'league_menu', eventParams);

    this.setState(menuState);

    if (!this.props.isMobile) {
      document.addEventListener('mousemove', this.handleMouseMove);

      // Timeout here allows time for the submenu to render into DOM before getting the rect.
      setTimeout(() => {
        const rect = this.dropdownMenu.current.getBoundingClientRect();
        this.upperLeft = { x: rect.left, y: rect.top };
      }, 0);
    }
  }

  handleMouseLeave(menuName, league = null) {
    clearTimeout(this.menuTimer);

    const eventParams = { type: 'close' };

    if (menuName !== 'showDropdownMenu' && league) {
      eventParams.league_name = league;
    }

    logEvent(menuName === 'showDropdownMenu' ? 'site_menu' : 'league_menu', eventParams);
    this.setState({ [menuName]: null });
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleKeyPress(e) {
    e.preventDefault();

    if (e.key === 'Enter' || e.key === ' ') {
      if (this.state.showDropdownMenu) {
        this.handleMouseLeave('showDropdownMenu');
      } else {
        this.handleMouseOver('showDropdownMenu');
      }
    }
  }

  /*
    Handles the case where the mouse leaves the main menu but the user is trying to move
    the mouse to a menu item.  We store the last two mouse positions and compare the slope of
    the previous position and the current position in relation to the upper left corner of the menu.
    If the slope is decreasing we can assume the user might be moving towards the menu, so delay the closing.
  */

  possiblyClose(menuName, league = null) {
    if (this.props.isMobile) {
      this.handleMouseLeave();
      return;
    }

    this.menuTimer = setTimeout(() => {
      const location = this.mouseLocations[this.mouseLocations.length - 1];
      const prevLocation = this.mouseLocations[0];

      let decreasingSlope = 0;
      let prevDecreasingSlope = 0;

      if (location && prevLocation && this.upperLeft) {
        decreasingSlope = Math.abs(slope(location, this.upperLeft));
        prevDecreasingSlope = Math.abs(slope(prevLocation, this.upperLeft));
      }

      if (decreasingSlope < prevDecreasingSlope) {
        this.possiblyClose(menuName, league);
      } else {
        this.handleMouseLeave(menuName, league);
      }
    }, 0);
  }

  handleMenuItemClick(league) {
    logEvent('site_menu', {
      type: 'navigation',
      league,
    });
    const menuState = {};
    this.dropdownMenuKeyList.forEach(elem => {
      menuState[elem] = false;
    });
    this.setState(menuState);
  }

  renderHome() {
    return (
      <div>
        <h2 className={css.menuHeader}>Home</h2>
        <ul>
          {this.props.menu.home.map(item => (
            <li>
              <Link
                route={item.has_news ? `/${item.slug}/news` : `/${item.slug}`}
                onClick={e => {
                  e.stopPropagation();
                  this.handleMenuItemClick(item.slug);
                }}
                key={item.slug}
              >
                <a>
                  <div className={css.menuElement}>
                    {item.medium_name.length < 20 ? item.medium_name : item.short_name}
                  </div>
                </a>
              </Link>
            </li>
          ))}
          {this.props.menu.spotlights.map(item => (
            <li>
              <Link
                route={item.web_url}
                onClick={e => {
                  e.stopPropagation();
                  this.handleMenuItemClick(item.web_url);
                }}
                key={item.id}
              >
                <a>
                  <div className={css.menuElement}>{item.menu_title}</div>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderLeagues() {
    return (
      <div>
        <h2 className={css.menuHeader}>All Leagues</h2>
        <div className={`${css.flexWrapper} ${css.dropdownMenus}`}>
          {this.state.leagues.map(column => (
            <ul className={css.leagueElement} key={`column-${column[0].slug}`}>
              {column.map(item => (
                <li key={item.slug}>
                  <Link
                    route={item.has_news ? `/${item.slug}/news` : `/${item.slug}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.handleMenuItemClick(item.slug);
                    }}
                  >
                    <a>
                      <div
                        className={`${css.menuElement}
                          ${
                            item.short_name === this.props.currentLeague.short_name
                              ? css.selected
                              : ''
                          }`}
                      >
                        <span
                          className={`${css[item.sport_name] ? css[item.sport_name] : ''}
                            ${css.leagueIcon}`}
                        />
                        <div className={css.menuElementInnerContainer}>
                          <span>{item.medium_name}</span>
                          {item.events_in_progress ? <LiveNowChip siteMenu /> : null}
                        </div>
                      </div>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    );
  }

  renderSectionDropdown(league, isFirst) {
    const menuName = `show${league.short_name.toUpperCase()}Menu`;
    this.dropdownMenuKeyList.push(menuName);

    return (
      <div
        className={`${css.leagueMenu} ${this.state[menuName] ? css.hoverMenu : ''}`}
        key={league.short_name}
      >
        <Link
          key={`${league.slug}Nav`}
          route={`/${league.slug}/news`}
          onClick={e => {
            e.stopPropagation();
            this.handleMenuItemClick(league.short_name);
          }}
        >
          <a className={css.link}>
            <ScrollMenuElement name={league.short_name.toUpperCase()} navMenu firstCard={isFirst} />
          </a>
        </Link>
        {this.state[menuName] && (
          <div className={css.dropdownMenu} ref={this.dropdownMenu}>
            <div className={`${css.menuHeader} col-md-2`} style={{ padding: '20px' }}>
              {league.sections.map((elem, i) => (
                <Link
                  key={`${league.slug}${elem.name}dropdown`}
                  route={`/${league.slug}/${elem.key}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.handleMenuItemClick(league.short_name);
                  }}
                >
                  <a>
                    <div
                      key={league.short_name}
                      className={css.menuElement}
                      style={i === league.sections.length - 1 ? { paddingBottom: '0' } : null}
                    >
                      {elem.name}
                    </div>
                  </a>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { showDropdownMenu } = this.state;
    const league = this.props.currentLeague.short_name || 'LEAGUES';
    const topNews = league.toLowerCase() === 'top news' || league.toLowerCase() === 'trending';

    return (
      <div className={css.flexWrapper}>
        <FocusTrap
          focusTrapOptions={{
            allowOutsideClick: true,
          }}
          active={showDropdownMenu}
        >
          <div
            className={`${css.mainMenu} ${topNews ? css.hide : ''}`}
            aria-label="Main menu"
            onMouseOver={() => this.handleMouseOver('showDropdownMenu')}
            onMouseLeave={() => this.possiblyClose('showDropdownMenu')}
          >
            <div className={css.hoverMenuTrick} />
            <div className={css.menuButton}>
              <div
                className={`${css.hamburgerWrapper} ${showDropdownMenu ? css.hoverMenu : ''}`}
                role="button"
                tabIndex={0}
                aria-label={showDropdownMenu ? 'Close Main Menu' : 'Open Main Menu'}
                aria-expanded={(!!showDropdownMenu).toString()}
                onKeyPress={this.handleKeyPress}
              >
                <span className={`${css.hamburger} ${css.leagueIcon}`} />
              </div>
            </div>
            {showDropdownMenu && (
              <div className={css.dropdownMenu} ref={this.dropdownMenu}>
                <div className={css.dropdownWrapper}>
                  <div>
                    {this.renderHome()}
                    <h2 className={css.menuHeader} style={{ marginTop: '10px' }}>
                      Follow Us
                    </h2>
                    <ul>
                      <li>
                        <a href="https://facebook.com/theScore">
                          <div className={css.menuElement}>Facebook</div>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/theScore">
                          <div className={css.menuElement}>Twitter</div>
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/thescore">
                          <div className={css.menuElement}>Instagram</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className={css.leagues}>{this.renderLeagues()}</div>
                </div>
              </div>
            )}
          </div>
        </FocusTrap>
        <div className={`${css.menuButton} ${css.divider}`}>
          <a className={css.leagueHeader} href={getLeagueUrl(this.props.currentLeague.slug)}>
            {league.toUpperCase()}
          </a>
        </div>
        {topNews &&
          slice(this.state.leagues[0], 0, 4).map((elem, i) =>
            this.renderSectionDropdown(elem, i === 0)
          )}
      </div>
    );
  }
}

const leagueShape = PropTypes.shape({
  slug: PropTypes.string,
  short_name: PropTypes.string,
  medium_name: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
});

DesktopMenu.propTypes = {
  currentLeague: leagueShape,
  menu: PropTypes.shape({
    home: PropTypes.arrayOf(leagueShape),
    all: PropTypes.arrayOf(leagueShape),
    show: PropTypes.arrayOf(leagueShape),
    more: PropTypes.arrayOf(leagueShape),
    spotlights: PropTypes.array,
  }).isRequired,
  isMobile: PropTypes.bool,
};

DesktopMenu.defaultProps = {
  currentLeague: noLeague,
  isMobile: false,
};

DesktopMenu.displayName = 'DesktopMenu';

const mapStateToProps = state => ({
  isMobile: state.userAgent.isMobile,
});

export default connect(mapStateToProps)(StyledComponent(DesktopMenu, [css]));
