import get from 'lodash/get';

import getLeaders, { getCategories, getPlayerSplits } from '~/util/getLeaders';
import actions from './actions';

const setLeaders = (leagueSlug, seasonType = 'regular', subSection, apiUri = null) => async (
  dispatch,
  getState
) => {
  dispatch(actions.setLeadersInitiated());

  const leadersSubGroup = subSection || seasonType;
  const leagueLeaders = get(getState(), `leadersData.cache.${leagueSlug}.${leadersSubGroup}`, null);
  let categoriesTypes = get(getState(), `leadersData.cache.${leagueSlug}.categories`, null);

  if (leagueLeaders) {
    dispatch(actions.setLeadersSucceeded(leagueSlug, leadersSubGroup, leagueLeaders));
    return Promise.resolve({ leaders: leagueLeaders, categoriesPerType: categoriesTypes });
  }

  try {
    if (!categoriesTypes) {
      categoriesTypes = await getCategories(leagueSlug);
      categoriesTypes = categoriesTypes.json;
    }
    dispatch(actions.setCategoriesLeadersSucceeded(leagueSlug, categoriesTypes));
  } catch (err) {
    dispatch(actions.setCategoriesLeadersFailed(err));
  }

  return getLeaders(leagueSlug, seasonType, categoriesTypes, apiUri)
    .then(response => {
      dispatch(actions.setLeadersSucceeded(leagueSlug, leadersSubGroup, response));
      return { leaders: response, categoriesPerType: categoriesTypes };
    })
    .catch(err => {
      dispatch(actions.setLeadersFailed(err));
      return [];
    });
};

const setPlayerSplits = (leagueSlug, splitType, leaders) => async (dispatch, getState) => {
  dispatch(actions.setPlayerSplitsInitiated());

  const playerIds = leaders.map(leader => leader.player.id);
  const storedSplits = get(
    getState(),
    `leadersData.cache.${leagueSlug}.playerSplits.${splitType}`,
    []
  );
  const splitPlayerIds = storedSplits.map(split => split.player.id);
  const playerIdDiff = playerIds.filter(id => !splitPlayerIds.includes(id));
  const splitsForPlayers = storedSplits.filter(split => playerIds.includes(split.player.id));

  if (!playerIdDiff.length) {
    dispatch(actions.setPlayerSplitsSucceeded(leagueSlug, splitType, storedSplits));
    return Promise.resolve(splitsForPlayers);
  }

  return getPlayerSplits(leagueSlug, splitType, playerIdDiff)
    .then(response => {
      dispatch(
        actions.setPlayerSplitsSucceeded(leagueSlug, splitType, [...storedSplits, ...response.json])
      );
      return [...splitsForPlayers, ...response.json];
    })
    .catch(err => {
      dispatch(actions.setPlayerSplitsFailed(err));
      return [];
    });
};

export default { setLeaders, setPlayerSplits };
