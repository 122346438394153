import types from './types';

const setBreakingNews = breakingNews => ({
  type: types.SET_BREAKING_NEWS,
  payload: breakingNews,
});

export default {
  setBreakingNews,
};
