/* global GET_THE_APP_MESSAGING_URL */
import React from 'react';
import StyledComponent from '~/components/Styled/Styled';
import css from '~/components/GetTheAppModal/GetTheAppModal.scss';
import logEvent from '~/util/analytics';
import fetch from '~/util/fetch';
import isMobile from '~/util/isMobile';
import { FormattedMessage, defineMessages, injectIntl, InjectedIntlProps } from 'react-intl';

const messages = defineMessages({
  error: {
    id: 'header.get_the_app.error',
    defaultMessage: 'An error occured. Please try again later.',
  },
  invalid_email: {
    id: 'header.get_the_app.invalid_email',
    defaultMessage: 'Invalid email address',
  },
  email_address: {
    id: 'header.get_the_app.email_address',
    defaultMessage: 'email address',
  },
});

const trackEvent = () => {
  logEvent('conversion', { type: 'stubpageheader', campaign: 'thescoreapp' });
};

type BaseProps = {
  getAppUrl?: string;
  getTheApp?: (...args: any[]) => string;
};

type State = {
  submitted: boolean;
  promptMessage: string;
  userEmail: string;
};

type GetTheAppModalProps = BaseProps & InjectedIntlProps;

export class GetTheAppModal extends React.Component<GetTheAppModalProps, State> {
  static displayName = 'GetTheAppModal';
  static defaultProps = {
    getAppUrl: 'https://thescore.app.link/stubpageheader',
    getTheApp: (
      <FormattedMessage
        id="header.get_the_app.download_the_app"
        defaultMessage="Download the app"
      />
    ),
  };

  static renderAppRating() {
    return (
      <div className={css.appRatingContainer}>
        <img alt="" className={css.appIcon} src="/static/images/the-score-app-icon.png" />
        <span className={css.appTitle}>
          <FormattedMessage id="header.get_the_app.apptitle" defaultMessage="theScore App" />
        </span>
        <br />
        <img alt="" src="/static/images/star.png" />
        <img alt="" src="/static/images/star.png" />
        <img alt="" src="/static/images/star.png" />
        <img alt="" src="/static/images/star.png" />
        <img alt="" src="/static/images/star.png" />
        <br />
        <span className={css.appDownloads}>
          <FormattedMessage
            id="header.get_the_app.app_rating"
            defaultMessage="200,000+ 5-star reviews"
          />
        </span>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      userEmail: '',
      promptMessage: '',
    };

    this.getTheAppButtonClicked = this.getTheAppButtonClicked.bind(this);
    this.toggle = this.toggle.bind(this);
    this.send = this.send.bind(this);
    this.emailInputChanged = this.emailInputChanged.bind(this);
  }

  getTheAppButtonClicked() {
    trackEvent();

    if (isMobile(navigator.userAgent)) {
      location.href = 'https://thescore.app.link/4IiNl704fL?campaign=basedesigndownloadtheapp';
    } else {
      location.href = 'https://get.thescore.com';
    }
  }

  toggle() {
    this.setState({
      submitted: false,
    });
  }

  emailInputChanged(evt) {
    this.setState({ userEmail: evt.target.value });
  }

  isFormValid() {
    const { intl: { formatMessage } } = this.props;
    const emailCheck = /[^@\s]+@[^@\s]+\.\w+/g;
    if (!this.state.userEmail.match(emailCheck) || this.state.userEmail === '') {
      this.setState({ promptMessage: formatMessage(messages.invalid_email) });
      return false;
    }

    this.setState({ promptMessage: '' });
    return true;
  }

  async send() {
    const { intl: { formatMessage } } = this.props;
    if (!this.isFormValid()) {
      return;
    }
    const inputType = 'email';
    const payload = this.state.userEmail;
    const formData = new FormData();
    formData.append(inputType, payload);
    formData.append('property', 'nation_network');

    // @ts-ignore expected 0-1 arguments from fetch, received two (fetch takes an options argument ts is wrong here)
    const response = await fetch(`${GET_THE_APP_MESSAGING_URL}/email`, {
      method: 'POST',
      body: formData,
    });

    if (response.status === 200) {
      this.setState({ submitted: true });
    } else {
      this.setState({ promptMessage: formatMessage(messages.error) });
    }
  }

  renderSignup() {
    const { intl: { formatMessage } } = this.props;
    const platformText = formatMessage(messages.email_address);
    return (
      <>
        <>
          <br />
          <strong>
            <FormattedMessage id="header.get_the_app.download_now" defaultMessage="DOWNLOAD NOW!" />
          </strong>
          <div>
            <>
              <FormattedMessage
                id="header.get_the_app.instructions"
                defaultMessage="Enter your {platformText} and we'll send you a link to download"
                values={{ platformText }}
              />
            </>
          </div>
          {this.renderEmailField()}
          <div className={css.promptMessage}>{this.state.promptMessage}&nbsp;</div>
          <button className={css.sendButton} onClick={this.send}>
            <FormattedMessage id="header.get_the_app.send" defaultMessage="SEND" />
          </button>
        </>
      </>
    );
  }

  renderEmailField() {
    const { intl: { formatMessage } } = this.props;
    return (
      <input
        placeholder={formatMessage(messages.email_address)}
        onChange={this.emailInputChanged}
      />
    );
  }

  render() {
    return (
      <>
        <button className={css.getTheAppButton} onClick={this.getTheAppButtonClicked}>
          {this.props.getTheApp}
        </button>
      </>
    );
  }
}

export default injectIntl(StyledComponent(GetTheAppModal, [css]));
