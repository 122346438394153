import Leagues, { home } from '~/constants/Leagues';

/* global API_URL */

const apiOptions = {
  headers: {
    'X-Api-Version': '1.7.10',
    'X-Country-Code': 'CA',
    'X-User-Agent': 'theScore (Web)',
  },
};

const formatLeagues = leagues => ({
  home,
  show: leagues.show,
  more: leagues.more,
  all: [...home, ...leagues.show, ...leagues.more],
});

const getMenu = async () =>
  fetch(`${API_URL}/meta/leagues`, apiOptions)
    .then(response => response.json())
    .then(leagues => formatLeagues(leagues.leagues))
    .catch(() => Leagues);

export default getMenu;
