/* global API_URL */
// eslint-disable-next-line no-unused-vars
import formatGame, { FormattedGame } from '~/util/formatGame';
import flatten from 'lodash/flatten';
import fetch, { handleResponse } from './fetch';

declare var API_URL: string;

const dateQueryParam = leagueSlug => {
  switch (leagueSlug) {
    case 'formula1':
    case 'nascar':
    case 'nasnw':
      return 'race_date';
    case 'atp':
    case 'wta':
      return 'match_date';
    default:
      return 'game_date';
  }
};

export const byDate = (
  query: string[],
  startDate: string,
  endDate: string,
  leagueSlug?: string
): string[] => {
  if (!startDate || !endDate) return query;

  return [...query, `${dateQueryParam(leagueSlug)}.in=${startDate},${endDate}`];
};

export const byNumGames = (query: string[], numGames: number = -1): string[] => [
  ...query,
  `limit=${numGames}`,
  `rpp=${numGames}`,
];

export const byLeagues = (query: string[], leagues: string[]): string[] => {
  if (!leagues || !leagues.length) return query;
  return [...query, `leagues=${leagues.join(',')}`];
};

const fetchScores = async (leagueSlug: string, queryOptions: string[]): Promise<Game[]> => {
  const query = `${API_URL}/${leagueSlug}/events?${queryOptions.join('&')}`;

  try {
    const response = await fetch(query).then(handleResponse);
    return response.json;
  } catch (errors) {
    return [];
  }
};

const fetchUpcomingScores = async (leagueSlug: string, queryOptions): Promise<Game[]> => {
  const query = `${API_URL}/${leagueSlug}/events/upcoming?${queryOptions.join('&')}`;

  try {
    const response = await fetch(query).then(handleResponse);
    return response.json;
  } catch (errors) {
    return [];
  }
};

export const getScores = async (
  leagueSlug: string,
  startDate: string,
  endDate: string,
  numGames: number = -1
): Promise<FormattedGame[]> => {
  let queryOptions = [];
  queryOptions = byDate(queryOptions, startDate, endDate, leagueSlug);
  queryOptions = byNumGames(queryOptions, numGames);

  let scores: Game[] = await fetchScores(leagueSlug, queryOptions);

  if (!scores.length) {
    queryOptions = byNumGames([], 10);
    scores = await fetchUpcomingScores(leagueSlug, queryOptions);
  }

  return scores.map(game => formatGame(game));
};

export const getMultisportScores = async (
  leagues: string[],
  startDate: string,
  endDate: string,
  numGames: number = null
) => {
  let queryOptions = [];
  queryOptions = byLeagues(queryOptions, leagues);
  queryOptions = byDate(queryOptions, startDate, endDate);
  queryOptions = byNumGames(queryOptions, numGames);

  const scores: Object = await fetchScores('multisport', queryOptions);

  const leagueObjs = Object.keys(scores).map(key => scores[key].league);

  const events = leagueObjs.map(league => {
    const eventsArray = scores[league.slug].events;

    return eventsArray.map(event => formatGame({ ...event, league }));
  });

  return flatten(events);
};
