import types from './types';

const setEventRecordsInitiated = () => ({
  type: types.FETCH_EVENT_RECORDS_INITIATED,
  payload: null,
});
const setEventRecordsSucceeded = records => ({
  type: types.FETCH_EVENT_RECORDS_SUCCEEDED,
  payload: records,
});
const setEventRecordsFailed = error => ({ type: types.FETCH_EVENT_RECORDS_FAILED, payload: error });

export default {
  setEventRecordsInitiated,
  setEventRecordsSucceeded,
  setEventRecordsFailed,
};
