import Head from 'next/head';

const PixelTracking = () => (
  <Head>
    <script>
      {/* eslint-disable */
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.setAttribute('class', 'optanon-category-C0001');
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TQN8B8H')}
    </script>
  </Head>
);

export default PixelTracking;
