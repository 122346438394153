import types from './types';

const fetchConferencesInitiated = () => ({
  type: types.FETCH_CONFERENCES_INITIATED,
});
const fetchConferencesSucceeded = (leagueSlug, conferences) => ({
  leagueSlug,
  type: types.FETCH_CONFERENCES_SUCCEEDED,
  payload: conferences,
});
const fetchConferencesFailed = error => ({ type: types.FETCH_CONFERENCES_FAILED, payload: error });

export default {
  fetchConferencesInitiated,
  fetchConferencesSucceeded,
  fetchConferencesFailed,
};
