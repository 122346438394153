import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultInterstitial from '~/components/GetTheApp/DefaultInterstitial/DefaultInterstitial';
import AlternativeInterstitial from '~/components/GetTheApp/AlternativeInterstitial/AlternativeInterstitial';
import WinterOlympicsInterstitial from '~/components/GetTheApp/WinterOlympicsInterstitial/WinterOlympicsInterstitial';
import { FormattedMessage } from 'react-intl';

import setInterstitalDisabled from '~/state/interstital/operations';

import logEvent from '~/util/analytics';
import css from './GetTheApp.scss';

const interstitialTypes = {
  default: DefaultInterstitial,
  alternative: AlternativeInterstitial,
  wolym: WinterOlympicsInterstitial,
};

let start = 0;
let change = 200; // default, should be set later
let currentTime = 0;
const increment = 20;
const duration = 300;

const copyVariations = {
  feature_copy: {
    title: (
      <FormattedMessage
        id="get_the_app.title"
        defaultMessage="Find out why millions of sports fans trust theScore as their number one sports app!"
      />
    ),
    bulletPoint1: (
      <FormattedMessage
        id="get_the_app.bulletPoint1"
        defaultMessage="In-depth stats & matchup coverage"
      />
    ),
    bulletPoint2: (
      <FormattedMessage
        id="get_the_app.bulletPoint2"
        defaultMessage="Lightning fast scores and news alerts"
      />
    ),
    bulletPoint3: (
      <FormattedMessage
        id="get_the_app.bulletPoint3"
        defaultMessage="Videos & news of 30+ leagues"
      />
    ),
    copy: 'featurescopy',
  },
};

const buttonVariations = {
  download_the_app: {
    label: <FormattedMessage id="get_the_app.download_app" defaultMessage="Download the app" />,
    name: 'downloadtheapp',
  },
};

const easeInOutQuad = time => {
  let newTime = time / duration * 2;
  if (newTime < 1) return change / 2 * newTime * newTime + start;
  newTime -= 1;
  return -(change / 2) * (newTime * (newTime - 2) - 1) + start;
};

const animateScroll = () => {
  currentTime += increment;
  document.documentElement.scrollTop = easeInOutQuad(currentTime);
  document.body.scrollTop = easeInOutQuad(currentTime);
  if (currentTime < duration) {
    setTimeout(animateScroll, increment);
  }
};

const scrollDown = element => {
  start = document.body.scrollTop || document.documentElement.scrollTop;
  currentTime = 0;
  change = element ? element.offsetHeight + 1 : 0;
  animateScroll();
};

const handleGetTheAppClick = (link, experiments = []) => {
  let eventProps = {};

  experiments.forEach(exp => {
    if (exp.active || experiments.length === 1) {
      eventProps = {
        ...eventProps,
        ...{
          variation: exp.get('interstitial_variation'),
          copy: exp.get('button_variation'),
        },
      };
    }
  });

  logEvent('conversion', {
    type: link,
    campaign: 'thescoreapp',
    ...eventProps,
  });
};

class GetTheApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.refHandler = this.refHandler.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
  }

  componentDidMount() {
    logEvent('interstitial_shown');
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // After an initial page load, disable the Interstitial.
    if (!this.props.interstitialDisabled) {
      this.props.setInterstitalDisabled(true);
    }

    window.removeEventListener('scroll', this.handleScroll);
  }

  refHandler(node) {
    this.banner = node;
  }

  scrollDown() {
    scrollDown(this.banner);
  }

  handleScroll() {
    const bannerScrollBottom = this.banner ? this.banner.getBoundingClientRect().bottom : null;
    const bannerHeight = window.innerHeight;

    clearTimeout(this.isScrolling);

    this.isScrolling = setTimeout(() => {
      if (bannerScrollBottom && bannerScrollBottom < 0 && this.state.show) {
        this.setState({ show: false });
        const isIos = /iPad|iPhone/.test(navigator.userAgent);
        if (isIos) {
          document.body.scrollTop = window.pageYOffset - bannerHeight;
          document.documentElement.scrollTop = window.pageYOffset - bannerHeight;
        }
      }
    }, 500);
  }

  render() {
    const Interstitial = interstitialTypes[this.props.leagueSlug] || interstitialTypes.default;

    if (!this.state.show || this.props.interstitialDisabled) return null;

    return (
      <div className={css.fullScreenContainerScrolldown} ref={this.refHandler}>
        <div className={css.getTheAppContainer}>
          <Interstitial
            handleGetTheAppClick={handleGetTheAppClick}
            scrollDown={this.scrollDown}
            isAndroid={this.props.isAndroid}
            copyText={copyVariations.feature_copy}
            buttonText={buttonVariations.download_the_app}
            bixbyUser={this.props.bixbyUser}
          />
        </div>
      </div>
    );
  }
}

GetTheApp.propTypes = {
  userId: PropTypes.string.isRequired,
  experiments: PropTypes.array,
  isAndroid: PropTypes.bool,
  leagueSlug: PropTypes.string,
  bixbyUser: PropTypes.bool,
  interstitialDisabled: PropTypes.bool,
  setInterstitalDisabled: PropTypes.func,
};

GetTheApp.defaultProps = {
  userId: '',
  experiments: [],
  isAndroid: true,
  leagueSlug: '',
  bixbyUser: false,
  interstitialDisabled: false,
  setInterstitalDisabled: () => {},
};

const mapStateToProps = state => ({
  interstitialDisabled: state.interstitialDisabled.interstitialDisabled,
  userId: state.abTest.data.userId,
  experiments: state.abTest.data.experiments,
  isAndroid: state.userAgent.isAndroid,
  leagueSlug: state.pageData.currentLeague.slug,
  bixbyUser: state.userAgent.isBixbyUser,
});

GetTheApp.displayName = 'GetTheApp';

const mapDispatchToProps = dispatch => ({
  setInterstitalDisabled: bindActionCreators(
    setInterstitalDisabled.setInterstitalDisabled,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledComponent(GetTheApp, [css]));
