import get from 'lodash/get';
import types from './types';

const standingsReducer = (
  state: any = { isLoading: false },
  { type, payload, leagueSlug, standingsType, conference }
) => {
  switch (type) {
    case types.FETCH_STANDINGS_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_STANDINGS_SUCCEEDED: {
      const standings = get(state, `cache.${leagueSlug}`, {});
      if (!conference) {
        standings[standingsType] = payload;
      } else {
        standings[standingsType] = get(standings, standingsType, {});
        standings[standingsType][conference] = payload;
      }

      const cache = get(state, 'cache', {});
      cache[leagueSlug] = { ...cache[leagueSlug], ...standings };

      return {
        ...state,
        cache: { ...state.cache, ...cache },
        data: payload,
        type: standingsType,
        isLoading: false,
      };
    }

    case types.FETCH_STANDINGS_FAILED: {
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default standingsReducer;
