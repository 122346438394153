import types from './types';

const setTeamInitiated = () => ({ type: types.FETCH_TEAM_INITIATED, payload: null });
const setTeamSucceeded = team => ({ type: types.FETCH_TEAM_SUCCEEDED, payload: team });
const setTeamFailed = error => ({ type: types.FETCH_TEAM_FAILED, payload: error, error: true });

export default {
  setTeamInitiated,
  setTeamSucceeded,
  setTeamFailed,
};
