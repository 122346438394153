import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import css from './WinterOlympicsInterstitial.scss';

const WinterOlympicsInterstitial = props => (
  <div>
    <p className={css.caption}>
      Get <b>theScore</b> for solid <span className={css.gold}>GOLD</span> coverage of the<br />
      <span className={css.large}>2018 WINTER GAMES</span>
    </p>
    <div className={css.flexContainer}>
      <img
        className={css.ctaPhoto}
        src={
          props.isAndroid
            ? '/static/images/device-interstitial-android.png'
            : '/static/images/device-interstitial-ios.png'
        }
        alt=""
      />
      <ul className={css.list}>
        <li className={css.listElement}>
          <div className={css.bullet}>
            <span className={css.gold}>•</span>
          </div>
          <div className={css.text}>Follow your country for medal updates and daily recaps</div>
        </li>
        <li className={css.listElement}>
          <div className={css.bullet}>
            <span className={css.silver}>•</span>
          </div>
          <div className={css.text}>Complete event results and final standings</div>
        </li>
        <li className={css.listElement}>
          <div className={css.bullet}>
            <span className={css.bronze}>•</span>
          </div>
          <div className={css.text}>Full-scale news coverage for every amazing moment</div>
        </li>
      </ul>
    </div>
    <a href="https://thescore.app.link/kLv56CabaK">
      <button
        className={css.downloadButton}
        onClick={() => {
          props.handleGetTheAppClick('stubpagedownloadCTA', props.experiments);
        }}
      >
        Download the app
      </button>
    </a>
    <a
      className={css.appStoreButton}
      href="https://thescore.app.link/zazhQYiCYG"
      onClick={() => {
        props.handleGetTheAppClick('Get_theScoreAppiOS', props.experiments);
      }}
    >
      <img
        src="/static/images/app-store.png"
        srcSet="/static/images/app-store.png 1x, /static/images/app-store@2x.png 2x"
        alt=""
      />
    </a>
    <a
      className={css.playStoreButton}
      href="https://thescore.app.link/TegVgYtCYG"
      onClick={() => {
        props.handleGetTheAppClick('Get_theScoreAppAndroid', props.experiments);
      }}
    >
      <img
        src="/static/images/play-store.png"
        srcSet="/static/images/play-store.png 1x, /static/images/play-store@2x.png 2x"
        alt=""
      />
    </a>

    <a
      onClick={() => {
        props.scrollDown(props.banner);
      }}
      role="button"
      tabIndex={0}
    >
      <p className={css.moreContentText}>Scroll down to see full content</p>
      <p className={css.moreContentChevron}>&#8964;</p>
    </a>
  </div>
);

WinterOlympicsInterstitial.propTypes = {
  handleGetTheAppClick: PropTypes.func,
  scrollDown: PropTypes.func,
  isAndroid: PropTypes.bool,
  experiments: PropTypes.array,
  banner: PropTypes.node,
};

WinterOlympicsInterstitial.defaultProps = {
  handleGetTheAppClick: () => {},
  scrollDown: () => {},
  isAndroid: true,
  experiments: [],
  banner: null,
};

WinterOlympicsInterstitial.displayName = 'WinterOlympicsInterstitial';

export default StyledComponent(WinterOlympicsInterstitial, [css]);
