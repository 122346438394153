import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import MobileMenu from '~/components/MobileMenu/MobileMenu';
import DesktopMenu from '~/components/DesktopMenu/DesktopMenu';
import SubNav from '~/components/SubNav/SubNav';
import { noLeague } from '~/constants/Leagues';
import { connect } from 'react-redux';

import css from '~/components/Nav/Nav.scss';

const Nav = ({ currentLeague, currentSection, menu, renderMobile, renderDesktop }) => (
  <nav className={css.nav}>
    {renderMobile && (
      <div className={css.mobileMenu}>
        <MobileMenu currentLeague={currentLeague} menu={menu} />
      </div>
    )}
    {renderDesktop && (
      <Fragment>
        <span className={css.divider}>&nbsp;</span>
        <div className={css.desktopMenu}>
          <DesktopMenu currentLeague={currentLeague} menu={menu} />
        </div>
      </Fragment>
    )}
    <div className={css.subNavWrap}>
      <SubNav league={currentLeague} selected={currentSection} />
    </div>
  </nav>
);

const leagueShape = PropTypes.shape({
  slug: PropTypes.string,
  short_name: PropTypes.string,
  medium_name: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
});

Nav.propTypes = {
  currentLeague: leagueShape,
  menu: PropTypes.shape({
    home: PropTypes.arrayOf(leagueShape),
    all: PropTypes.arrayOf(leagueShape),
    show: PropTypes.arrayOf(leagueShape),
    more: PropTypes.arrayOf(leagueShape),
  }).isRequired,
  currentSection: PropTypes.string,
  renderMobile: PropTypes.bool,
  renderDesktop: PropTypes.bool,
};

Nav.defaultProps = {
  currentLeague: noLeague,
  currentSection: null,
  renderMobile: true,
  renderDesktop: true,
};

Nav.displayName = 'Nav';

const mapStateToProps = state => ({
  menu: state.menuData.data,
  currentLeague: state.pageData.currentLeague,
  currentSection: state.pageData.currentSection,
});

export default connect(mapStateToProps)(StyledComponent(Nav, [css]));
