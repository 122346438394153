import React from 'react';

class UserIsTabbing extends React.Component {
  componentDidMount = () => {
    window.addEventListener('keydown', this.handleFirstTab);
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleFirstTab);
  };

  handleFirstTab = event => {
    if (event.key === 'Tab') {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', this.handleFirstTab);
      window.addEventListener('mousedown', this.handleMouseDownOnce);
    }
  };

  handleMouseDownOnce = () => {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', this.handleMouseDownOnce);
    window.addEventListener('keydown', this.handleFirstTab);
  };

  render() {
    return null;
  }
}

export default UserIsTabbing;
