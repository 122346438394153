exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Button__gradientBackground--1xQsx.Button__button--2VOdS {\n  background-image: linear-gradient(80deg, #0078fd, #0158ba 75%, #014da3);\n}\n\n.Button__solidBackground--1IOqD.Button__button--2VOdS {\n  background-color: #006dff;\n}\n\n.Button__button--2VOdS {\n  margin: 0 auto;\n  font-size: 14px;\n  font-weight: 700;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: normal;\n  letter-spacing: 0.3px;\n  text-align: center;\n  color: #fff;\n}\n.Button__button--2VOdS:hover {\n  background-color: #005abe;\n}", ""]);

// exports
exports.locals = {
	"gradientBackground": "Button__gradientBackground--1xQsx",
	"button": "Button__button--2VOdS",
	"solidBackground": "Button__solidBackground--1IOqD"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };