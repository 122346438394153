import types from './types';

const initialState = {
  data: null,
  isLoading: false,
  error: false,
};

const menuReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FETCH_MENU_STARTED:
      return {
        ...state,
        isLoading: true,
        error: false,
      };

    case types.FETCH_MENU_SUCCEEDED:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: false,
      };

    case types.FETCH_MENU_FAILED:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default menuReducer;
