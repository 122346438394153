import get from 'lodash/get';

import actions from './actions';
import getStandings from '~/util/getStandings';

const fetchStandings = ({ leagueSlug, apiUri, standingsType = 'standings', conference = null }) => (
  dispatch,
  getState
) => {
  dispatch(actions.fetchStandingsInitiated());

  const cacheAttr = `standingsData.cache.${leagueSlug}.${standingsType}${
    conference ? `.${conference}` : ''
  }`;
  const leagueStandings = get(getState(), cacheAttr, null);
  if (leagueStandings) {
    dispatch(
      actions.fetchStandingsSucceeded(leagueSlug, leagueStandings, standingsType, conference)
    );
    return Promise.resolve(leagueStandings);
  }

  return getStandings(leagueSlug, apiUri, conference)
    .then(response => {
      dispatch(
        actions.fetchStandingsSucceeded(leagueSlug, response.json, standingsType, conference)
      );
      return response.json;
    })
    .catch(err => {
      dispatch(actions.fetchStandingsFailed(err));
      return [];
    });
};

export default { fetchStandings };
