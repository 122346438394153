import types from './types';

const setLeadersInitiated = () => ({ type: types.FETCH_LEADERS_INITIATED, payload: null });
const setLeadersSucceeded = (leagueSlug, subSection, leaders) => ({
  type: types.FETCH_LEADERS_SUCCEEDED,
  leagueSlug,
  subSection,
  payload: leaders,
});
const setLeadersFailed = error => ({ type: types.FETCH_LEADERS_FAILED, payload: error });

const setCategoriesLeadersSucceeded = (leagueSlug, categories) => ({
  type: types.FETCH_CATEGORIES_SUCCEEDED,
  leagueSlug,
  payload: categories,
});
const setCategoriesLeadersFailed = error => ({
  type: types.FETCH_CATEGORIES_FAILED,
  payload: error,
});

const setPlayerSplitsInitiated = () => ({
  type: types.FETCH_PLAYER_SPLITS_INITIATED,
  payload: null,
});
const setPlayerSplitsSucceeded = (leagueSlug, splitType, playerSplits) => ({
  type: types.FETCH_PLAYER_SPLITS_SUCCEEDED,
  leagueSlug,
  splitType,
  payload: playerSplits,
});
const setPlayerSplitsFailed = error => ({ type: types.FETCH_PLAYER_SPLITS_FAILED, payload: error });

export default {
  setLeadersInitiated,
  setLeadersSucceeded,
  setLeadersFailed,
  setCategoriesLeadersSucceeded,
  setCategoriesLeadersFailed,
  setPlayerSplitsInitiated,
  setPlayerSplitsSucceeded,
  setPlayerSplitsFailed,
};
