import types from './types';

const setInterstitalDisabled = interstitialStatus => ({
  type: types.SET_INTERSTITIAL_DISABLED,
  payload: interstitialStatus,
});

export default {
  setInterstitalDisabled,
};
