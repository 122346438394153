import Head from 'next/head';

const Facebook = () => (
  <Head>
    <script>
      {/* eslint-disable */
      (function(w, d, s, id) {
        var js;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.11';
        d.head.appendChild(js);

        w.fbAsyncInit = () => {
          window.FB.init({
            appId: '124052647629735',
            xfbml: true,
            version: 'v2.12',
          });
          window.FB.Event.subscribe('xfbml.render', () => {
            window.setTimeout(function() {
              window.dispatchEvent(new Event('image-loaded'));
            }, 0);
          });
        };
        /*eslint-disable*/
      })(window, document, 'script', 'facebook-jssdk')}
    </script>
  </Head>
);

export default Facebook;
