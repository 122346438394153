import React from 'react';
import { operations } from '~/state/pageData';
import { operations as locationOperations } from '~/state/location';
import menuOps from '~/state/menu/operations';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

function withPageData(WrappedComponent) {
  class WithPageData extends React.Component {
    static async getInitialProps(context) {
      const leagueSlug =
        context.asPath === '/'
          ? 'top_news'
          : context.query.leagueSlug || context.query.slug || null;
      const section = context.query.section || null;

      if (context.store) {
        if (!context.store.getState().menuData.data) {
          await context.store.dispatch(menuOps.fetchMenu());
        }

        context.store.dispatch(
          operations.setLeague(leagueSlug, context.store.getState().menuData.data)
        );
        context.store.dispatch(operations.setSection(section));

        if (context.isServer) {
          const userIP = context.req.headers['x-forwarded-for'] || null;
          await context.store.dispatch(locationOperations.setUserLocation(userIP));
        }
      }

      const newContext = {
        ...context,
        leagueSlug,
        section,
      };
      return WrappedComponent.getInitialProps(newContext);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WithPageData.displayName = `WithPageData(${getDisplayName(WrappedComponent)})`;
  return WithPageData;
}

export default withPageData;
