import { polyfill, Promise } from 'es6-promise';
import fetch from '~/util/fetch';
/* global SITE_URL */

polyfill();
const userLocation = {
  promise: null,
  resolved: false,
};

const locationUrl = `${SITE_URL}/location.json`;

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function resolveLocation(resolveFunc) {
  return function resolveResponse(response) {
    userLocation.countryCode = response.headers.get('X-Country-Code');
    userLocation.city = response.headers.get('X-City');
    userLocation.region = response.headers.get('X-Region');
    userLocation.metroCode = response.headers.get('X-Metro-Code');

    userLocation.resolved = true;

    resolveFunc(userLocation);
  };
}

userLocation.promise = new Promise((resolve, reject) => {
  fetch(locationUrl)
    .then(checkStatus)
    .then(resolveLocation(resolve))
    .catch(e => reject(e));
});

export const getUserLocation = (userIP = null) => {
  const options = userIP ? { headers: { 'X-Forwarded-For': userIP } } : null;

  return new Promise((resolve, reject) => {
    fetch(locationUrl, options)
      .then(checkStatus)
      .then(resolveLocation(resolve, userIP))
      .catch(e => reject(e));
  });
};

export default userLocation;
