import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import * as reducers from '.';

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
/* eslint-enable */

const rootReducer = combineReducers({
  ...reducers,
});

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

export const initStore = (initialState = {}) => createStore(rootReducer, initialState, enhancer);

export default initStore;
