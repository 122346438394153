import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import actions from './actions';

const storeUserAgent = ua => (dispatch, getState) =>
  isEmpty(get(getState(), 'userAgent', {})) && dispatch(actions.storeUserAgent(ua));

const storeBixbyFlag = actions.storeBixbyFlag;

export default {
  storeUserAgent,
  storeBixbyFlag,
};
