import { combineReducers } from 'redux';
import types from './types';

const trendingContentReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.STORE_CONTENT: {
      return {
        ...state,
        [payload.id]: payload,
      };
    }
    default:
      return state;
  }
};

const articleReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.STORE_ARTICLE: {
      return {
        ...state,
        [payload.id]: payload,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  trendingContent: trendingContentReducer,
  articles: articleReducer,
});
