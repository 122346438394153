import types from './types';

const fetchMenuStarted = () => ({
  type: types.FETCH_MENU_STARTED,
  payload: null,
});

const fetchMenuSucceeded = menu => ({
  type: types.FETCH_MENU_SUCCEEDED,
  payload: menu,
});

const fetchMenuFailed = error => ({
  type: types.FETCH_MENU_FAILED,
  payload: error,
});

export default {
  fetchMenuStarted,
  fetchMenuSucceeded,
  fetchMenuFailed,
};
