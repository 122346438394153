import { noLeague } from '~/constants/Leagues';
import types from './types';

const initialState = {
  schedule: {
    current_season: [],
    current_group: {},
  },
  events: [],
  currentSection: null,
  routeParams: {},
  adConfig: {},
  currentLeague: noLeague,
  isLoading: false,
  player: {},
  playerSummary: [],
  playerRecords: [],
  standingsWildcard: [],
  relatedNews: {
    relatedContent: [],
  },
  roster: [],
  statusCode: 200,
  selectedDate: '',
};

const pageDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_LEAGUE: {
      return { ...state, currentLeague: payload };
    }
    case types.SET_ROUTE_PARAMS: {
      return { ...state, routeParams: payload };
    }
    case types.SET_SECTION: {
      return { ...state, currentSection: payload };
    }
    case types.SET_AD_CONFIG: {
      return { ...state, adConfig: payload };
    }
    case types.SET_SCHEDULE: {
      return { ...state, schedule: payload };
    }
    case types.SET_EVENTS_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.SET_EVENTS_COMPLETED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.SET_EVENTS: {
      return { ...state, events: payload };
    }
    case types.SET_STANDINGS: {
      return { ...state, standings: payload };
    }
    case types.SET_PLAYOFF_STANDINGS: {
      return { ...state, standingsPlayoffs: payload };
    }
    case types.SET_WILDCARD_STANDINGS: {
      return { ...state, standingsWildcard: payload };
    }
    case types.SET_TEAM: {
      return { ...state, team: payload };
    }
    case types.SET_TEAM_ROSTER: {
      return { ...state, roster: payload };
    }
    case types.SET_TEAM_SCHEDULE_PREVIOUS: {
      return { ...state, teamSchedulePrevious: payload };
    }
    case types.SET_TEAM_SCHEDULE_CURRENT: {
      return { ...state, teamScheduleCurrent: payload };
    }
    case types.SET_TEAM_SCHEDULE_UPCOMING: {
      return { ...state, teamScheduleUpcoming: payload };
    }
    case types.SET_PLAYER: {
      return { ...state, player: payload };
    }
    case types.SET_PLAYER_SUMMARY: {
      return { ...state, playerSummary: payload };
    }
    case types.SET_PLAYER_RECORDS: {
      return { ...state, playerRecords: payload };
    }
    case types.LEAGUE_RIVER_SUCCESS:
    case types.LEAGUE_RIVER_FAILURE:
    case types.LEAGUE_RIVER_REQUEST: {
      return { ...state, isLoading: payload };
    }
    case types.SET_LEAGUE_RIVER: {
      return { ...state, riverData: payload };
    }
    case types.SET_RELATED_NEWS: {
      return { ...state, relatedNews: payload };
    }
    case types.SET_STATUS_CODE: {
      return { ...state, statusCode: payload };
    }
    case types.SET_SELECTED_DATE: {
      return { ...state, selectedDate: payload };
    }
    case types.SET_CLEAR_SCHEDULE_AND_EVENTS_DATA: {
      return {
        ...state,
        events: initialState.events,
        schedule: initialState.schedule,
        selectedDate: initialState.selectedDate,
      };
    }
    default:
      return state;
  }
};

export default pageDataReducer;
