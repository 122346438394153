const FETCH_LEADERS_INITIATED = 'FETCH_LEADERS_INITIATED';
const FETCH_LEADERS_SUCCEEDED = 'FETCH_LEADERS_SUCCEEDED';
const FETCH_LEADERS_FAILED = 'FETCH_LEADERS_FAILED';
const FETCH_CATEGORIES_SUCCEEDED = 'FETCH_CATEGORIES_SUCCEEDED';
const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';
const FETCH_PLAYER_SPLITS_INITIATED = 'FETCH_PLAYER_SPLITS_INITIATED';
const FETCH_PLAYER_SPLITS_SUCCEEDED = 'FETCH_PLAYER_SPLITS_SUCCEEDED';
const FETCH_PLAYER_SPLITS_FAILED = 'FETCH_PLAYER_SPLITS_FAILED';

export default {
  FETCH_LEADERS_INITIATED,
  FETCH_LEADERS_SUCCEEDED,
  FETCH_LEADERS_FAILED,
  FETCH_CATEGORIES_SUCCEEDED,
  FETCH_CATEGORIES_FAILED,
  FETCH_PLAYER_SPLITS_INITIATED,
  FETCH_PLAYER_SPLITS_SUCCEEDED,
  FETCH_PLAYER_SPLITS_FAILED,
};
