/* global API_URL */
// / <reference path="../components/Leaders/Leaders.d.ts" />

import fetch, { handleResponse, handleRequestFailure } from './fetch';

export const getPlayerSplits = async (leagueSlug, splitType, playersIds) => {
  const playerList = playersIds.join(',');

  // Quick fix for MLB to which we don't ingest post season stats
  const seasonTypeQueryParam = leagueSlug === 'mlb' ? '&season_type=regular' : '';

  return fetch(`
    ${API_URL}/${leagueSlug}/${splitType}?player_id.in=${playerList}${seasonTypeQueryParam}
   `)
    .then(handleResponse)
    .catch(handleRequestFailure);
};

export const getCategories = async leagueSlug =>
  fetch(`${API_URL}/${leagueSlug}/categories/leaders`)
    .then(handleResponse)
    .catch(handleRequestFailure);

const getLeadersCategories = async (leagueSlug, categories, seasonType, apiUri) => {
  let leadersCategoriesUrl = apiUri ? `${API_URL}/${apiUri}` : `${API_URL}/${leagueSlug}/leaders`;
  leadersCategoriesUrl = `${leadersCategoriesUrl}?categories=${categories}`;
  leadersCategoriesUrl =
    leagueSlug === 'nfl' ? `${leadersCategoriesUrl}&league_name.eq=nfl` : leadersCategoriesUrl;

  if (seasonType) {
    return fetch(`${leadersCategoriesUrl}&season_type=${seasonType}`)
      .then(handleResponse)
      .catch(handleRequestFailure);
  }

  return fetch(leadersCategoriesUrl)
    .then(handleResponse)
    .catch(handleRequestFailure);
};

const getLeagueLeaders = async leagueSlug =>
  fetch(`${API_URL}/${leagueSlug}/leaders`)
    .then(handleResponse)
    .catch(handleRequestFailure);

const getLeaders = async (leagueSlug, seasonType, categoriesObj, apiUri) => {
  const categoryList = categoriesObj
    .map(categoryTypeObj =>
      categoryTypeObj.categories.map(categoryObj => categoryObj.category).join(',')
    )
    .join(',');

  if (categoryList) {
    const response = await getLeadersCategories(leagueSlug, categoryList, seasonType, apiUri);

    const leaders = response.json;
    const result = [];

    categoriesObj.forEach(categoryTypeObj => {
      const categoryType = categoryTypeObj.category_type;
      result.push({ categories: {} });
      if (categoryType) result[result.length - 1].categoryType = categoryType;

      categoryTypeObj.categories.forEach(categoryObj => {
        const { category, category_label } = categoryObj;
        result[result.length - 1].categories[category_label] = leaders[category];
      });
    });

    return result;
  }

  const leaders = await getLeagueLeaders(leagueSlug);
  return leaders.json;
};

export default getLeaders;
