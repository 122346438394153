export interface FormattedGame {
  boxscoreUrl: string;
  eventStatus: string;
  firstTeam?: FormattedTeam;
  gameClock?: { label: string; period: string };
  gameDate?: { date: string; final: boolean };
  league: string;
  secondTeam?: FormattedTeam;
  score?: { away: string; home: string };
  sportName: string;
}

export interface FormattedTeam {
  name: string;
  alignment: string;
  logoUrl: string;
}

/**
 * formatGame
 * @param {Object} gameRes The games response object from the sports api
 */

const formatGameJson = (gameRes: Game): FormattedGame => {
  const game: FormattedGame = {
    boxscoreUrl: gameRes.api_uri,
    league: gameRes.league.slug,
    sportName: gameRes.league.sport_name,
    eventStatus: gameRes.status,
  };

  game.firstTeam = formatTeam(gameRes.away_team, 'away', gameRes.league);
  game.secondTeam = formatTeam(gameRes.home_team, 'home', gameRes.league);

  if (gameRes.box_score) {
    game.gameClock = {
      period: gameRes.box_score.progress.segment_string,
      label:
        gameRes.status === 'in_progress'
          ? gameRes.box_score.progress.clock
          : gameRes.box_score.progress.clock_label,
    };
  }

  game.gameDate = {
    date: gameRes.game_date,
    final: gameRes.status === 'final',
  };

  if (gameRes.status !== 'pre_game') {
    game.score = {
      home: String(gameRes.box_score.score.home.score),
      away: String(gameRes.box_score.score.away.score),
    };
  }

  return game;
};

const leagueAdjustments = (game: FormattedGame) => {
  const leagueGame = game;
  switch (leagueGame.sportName) {
    case 'soccer':
      if (leagueGame.gameClock) {
        leagueGame.gameClock.period = '';
      }
      [leagueGame.firstTeam, leagueGame.secondTeam] = [leagueGame.secondTeam, leagueGame.firstTeam];
      break;

    // no default
  }
  return leagueGame;
};

const formatGame = (gameRes: Game) => {
  const game = formatGameJson(gameRes);
  return leagueAdjustments(game);
};

const formatTeam = (team: Team, alignment: string, league: League): FormattedTeam => {
  if (!team) {
    return {
      alignment,
      name: 'TBD',
      logoUrl: getLogoUrl(league.sport_name),
    };
  }

  return {
    alignment,
    name: team.abbreviation,
    logoUrl: team.logos.tiny,
  };
};

const getLogoUrl = (sportName: string): string => {
  switch (sportName) {
    case 'baseball':
    case 'basketball':
    case 'football':
    case 'hockey':
    case 'soccer':
      return `/static/vectors/sportIcons/${sportName}.svg`;
    default:
      return '/static/vectors/sportIcons/default.svg';
  }
};
export default formatGame;
