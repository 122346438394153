exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AlternativeInterstitial__container--3WhME {\n  height: 100%;\n  width: 100%;\n}\n\n.AlternativeInterstitial__caption--1Db3v {\n  display: block;\n  font-size: 13px;\n  letter-spacing: 1px;\n  text-align: center;\n  font-weight: 100;\n  color: #1e1f21;\n  margin-bottom: 20px;\n  margin-top: 30px;\n}\n.AlternativeInterstitial__caption--1Db3v .AlternativeInterstitial__large--sKC72 {\n  font-size: 22px;\n  font-weight: 700;\n  letter-spacing: 1.4px;\n}\n\n.AlternativeInterstitial__ctaContainer--3-jx4 {\n  display: inline-block;\n  vertical-align: middle;\n  width: auto;\n  height: 100%;\n}\n\n.AlternativeInterstitial__ctaPhoto--37hS2 {\n  width: auto;\n  max-width: 100%;\n  height: 80%;\n}\n\n.AlternativeInterstitial__gradient--1zRix {\n  position: absolute;\n  left: 0;\n  margin-top: -461px;\n  width: 100%;\n  height: 461px;\n  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgb(255, 255, 255) 83%);\n}\n\n.AlternativeInterstitial__span--C8nBB {\n  display: inline-block;\n  vertical-align: middle;\n  position: relative;\n  left: 6px;\n}\n\n.AlternativeInterstitial__downloadButton--8bOgB {\n  position: absolute;\n  border-radius: 2px;\n  width: 270px;\n  margin-top: 350px;\n  background-color: #0078fd;\n  box-shadow: 0 6px 8px 0 rgba(0, 120, 253, 0.5);\n  max-width: 311px;\n  height: 44px;\n  font-size: 14px;\n  font-weight: 700;\n  letter-spacing: 0.3px;\n  text-align: center;\n  left: calc(50% - 135px);\n  bottom: 135px;\n  color: #fff;\n}\n\n.AlternativeInterstitial__moreContentText--1RAcp {\n  position: absolute;\n  font-size: 12px;\n  line-height: 1.58;\n  letter-spacing: 0.3px;\n  font-weight: 400;\n  color: #888;\n  width: 100%;\n  bottom: 95px;\n  left: 0;\n}\n\n.AlternativeInterstitial__moreContentChevron--3iiIR {\n  position: absolute;\n  font-size: 20px;\n  line-height: 1.58;\n  letter-spacing: 0.3px;\n  color: #888;\n  width: 100%;\n  bottom: 75px;\n  left: 0;\n}", ""]);

// exports
exports.locals = {
	"container": "AlternativeInterstitial__container--3WhME",
	"caption": "AlternativeInterstitial__caption--1Db3v",
	"large": "AlternativeInterstitial__large--sKC72",
	"ctaContainer": "AlternativeInterstitial__ctaContainer--3-jx4",
	"ctaPhoto": "AlternativeInterstitial__ctaPhoto--37hS2",
	"gradient": "AlternativeInterstitial__gradient--1zRix",
	"span": "AlternativeInterstitial__span--C8nBB",
	"downloadButton": "AlternativeInterstitial__downloadButton--8bOgB",
	"moreContentText": "AlternativeInterstitial__moreContentText--1RAcp",
	"moreContentChevron": "AlternativeInterstitial__moreContentChevron--3iiIR"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };