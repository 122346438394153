import React from 'react';
import { Swipe, SwipeItem } from 'swipejs/react';
import Cookie from 'js-cookie';

const getDisplayName = WrappedComponent =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export default function withSwipe(WrappedComponent) {
  class WithSwipe extends React.Component {
    constructor() {
      super();
      this.state = {
        show: true,
      };

      this.onTransactionEnd = this.onTransactionEnd.bind(this);
    }

    componentDidMount() {
      if (!this.swipe) {
        return;
      }
      this.swipe.stop();
      setTimeout(() => {
        this.swipe.prev();
      }, 500);
    }

    onTransactionEnd(index) {
      if (index !== 1) {
        Cookie.set('notificationShown', true, {
          path: '/',
          expires: 1,
        });

        this.setState({ show: false });
      }
    }

    render() {
      return (
        <Swipe
          ref={swipe => {
            this.swipe = swipe;
          }}
          startSlide={2}
          transitionEnd={this.onTransactionEnd}
          style={{ display: this.state.show ? 'block' : 'none' }}
        >
          <SwipeItem style={{ width: '100vw' }} />
          <SwipeItem style={{ width: '100vw' }}>
            <WrappedComponent {...this.props} />
          </SwipeItem>
          <SwipeItem style={{ width: '100vw' }} />
        </Swipe>
      );
    }
  }

  WithSwipe.displayName = `WithSwipe(${getDisplayName(WrappedComponent)})`;

  return WithSwipe;
}
