import React from 'react';
import truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import withSwipe from '~/hocs/withSwipe';
import StyledComponent from '~/components/Styled/Styled';
import Link from '~/components/Link/Link';

import logEvent from '~/util/analytics';

import css from '~/components/BreakingNewsAlert/BreakingNewsAlert.scss';

const truncateText = text => truncate(text, { length: 100, separator: /,? +/ });

class BreakingNewsAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    logEvent('alert_click', {
      type: 'breaking_news',
      article_id: this.props.article.id,
    });
  }

  closeNotification() {
    this.setState({ show: false });
  }

  render() {
    const { article } = this.props;
    if (this.state.show) {
      return (
        <div className={css.container}>
          <div
            role="button"
            tabIndex="0"
            className={css.closeButton}
            onClick={e => {
              e.stopPropagation();
              this.closeNotification();
            }}
          />
          <Link route={`/news_alert_landing?articleId=${article.data.id}`} onClick={this.onClick}>
            <a>
              <div className={css.root}>
                <div className={css.leftColumn}>
                  <div className={css.appIcon} />
                </div>
                <div className={css.rightColumn}>
                  <div className={css.header}>
                    <div className={css.appName}>News Alert</div>
                  </div>
                  <div>
                    <div className={css.description}>
                      {truncateText(article.caption || article.data.title)}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>
      );
    }
    return null;
  }
}

BreakingNewsAlert.propTypes = {
  article: PropTypes.object.isRequired,
};

BreakingNewsAlert.displayName = 'BreakingNewsAlert';

export default withSwipe(StyledComponent(BreakingNewsAlert, [css]));
