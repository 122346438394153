import types from './types';

const eventRecordReducer = (state = { isLoading: false }, { type, payload }) => {
  switch (type) {
    case types.FETCH_EVENT_RECORDS_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_EVENT_RECORDS_SUCCEEDED: {
      const eventRecord = {};
      const eventRecordKey = `${payload.leagueSlug}/${payload.eventId}/${payload.recordType}`;
      eventRecord[eventRecordKey] = payload.data;
      return {
        ...state,
        cache: { ...state.cache, ...eventRecord },
        isLoading: false,
      };
    }

    case types.FETCH_EVENT_RECORDS_FAILED: {
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default eventRecordReducer;
