import { getUserLocation } from '~/util/userLocation';
import actions from './actions';

const setUserLocation = userIP => dispatch =>
  getUserLocation(userIP).then(location =>
    dispatch(
      actions.setUserLocation({
        countryCode: location.countryCode,
        city: location.city,
        region: location.region,
        metroCode: location.metroCode,
      })
    )
  );

export default {
  setUserLocation,
};
