exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SubNav__link--1XEsc:focus {\n  box-shadow: inset 0 0 0 2px #fff;\n  outline: none;\n}", ""]);

// exports
exports.locals = {
	"link": "SubNav__link--1XEsc"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };