import actions from './actions';

const setUtmSource = utmSource => dispatch => {
  dispatch(actions.setUtmSourceInitiated());
  if (utmSource) dispatch(actions.setUtmSourceSucceeded(utmSource));
  else dispatch(actions.setUtmSourceFailed(new Error('no UTM exists')));
};

export default {
  setUtmSource,
};
