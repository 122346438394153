/* global API_URL */

import fetch, { handleResponse, handleRequestFailure } from './fetch';

const getLeaguesLive = async () =>
  fetch(`${API_URL}/meta/leagues/live`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export default getLeaguesLive;
