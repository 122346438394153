import get from 'lodash/get';

import { getConferences } from '~/util/getConferences';
import actions from './actions';

const fetchConferences = leagueSlug => async (dispatch, getState) => {
  const conferences = get(getState(), `conferencesData.cache.${leagueSlug}`, null);
  if (conferences) return Promise.resolve(conferences);

  dispatch(actions.fetchConferencesInitiated());
  return getConferences(leagueSlug)
    .then(response => {
      dispatch(actions.fetchConferencesSucceeded(leagueSlug, response.json));
      return response.json;
    })
    .catch(err => {
      dispatch(actions.fetchConferencesFailed(err));
      return [];
    });
};

export default { fetchConferences };
