import types from './types';

const locationReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.SET_USER_LOCATION: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default locationReducer;
