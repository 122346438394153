import getOr from '~/util/getOr';
import { getEventRecords } from '~/util/getEvents';
import actions from './actions';

const setEventRecords = (leagueSlug, eventId, recordType) => (dispatch, getState) => {
  const eventRecordKey = `eventRecords.cache.${leagueSlug}/${eventId}/${recordType}`;
  const eventRecords = getOr(getState(), eventRecordKey, false);
  if (eventRecords) return Promise.resolve(eventRecords);

  dispatch(actions.setEventRecordsInitiated());
  return getEventRecords(leagueSlug, eventId, recordType)
    .then(response => {
      dispatch(
        actions.setEventRecordsSucceeded({ data: response.json, leagueSlug, eventId, recordType })
      );
      return response;
    })
    .catch(e => {
      dispatch(actions.setEventRecordsFailed(e));
      return [];
    });
};

export default { setEventRecords };
