import types from './types';

const fetchStandingsInitiated = () => ({ type: types.FETCH_STANDINGS_INITIATED, payload: null });
const fetchStandingsSucceeded = (leagueSlug, standings, standingsType, conference) => ({
  leagueSlug,
  standingsType,
  conference,
  type: types.FETCH_STANDINGS_SUCCEEDED,
  payload: standings,
});
const fetchStandingsFailed = error => ({ type: types.FETCH_STANDINGS_FAILED, payload: error });

export default {
  fetchStandingsInitiated,
  fetchStandingsSucceeded,
  fetchStandingsFailed,
};
