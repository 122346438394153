import { defaultLeagues } from '~/constants/Leagues';
import { getScores, getMultisportScores } from '~/util/scoresClient';
import moment from 'moment';
// types
const UPDATE_TICKER = 'UPDATE_TICKER';
const FETCH_TICKER_STARTED = 'FETCH_TICKER_STARTED';
const FETCH_TICKER_FAILED = 'FETCH_TICKER_FAILED';

// actions
export const updateTicker = payload => ({
  type: UPDATE_TICKER,
  payload,
});

export const fetchTickerStarted = () => ({
  type: FETCH_TICKER_STARTED,
});

export const fetchTickerFailed = error => ({
  type: FETCH_TICKER_FAILED,
  payload: [],
  error,
});

// operations
export const getTicker = league => async dispatch => {
  try {
    dispatch(fetchTickerStarted());
    let games = [];
    const date = moment();
    const endOfToday = moment(date)
      .add('days', '1')
      .startOf('day')
      .toISOString();

    const yesterday = moment(date)
      .subtract('days', '1')
      .startOf('day')
      .toISOString();

    const startDay = date.hour() > 11 ? date.startOf('day').toISOString() : yesterday;

    if (league === 'top_news' || league === 'trending') {
      const leaguesToFetch = defaultLeagues.map(leagueObj => leagueObj.slug);
      games = await getMultisportScores(leaguesToFetch, startDay, endOfToday, 4);
    } else {
      games = await getScores(league, startDay, endOfToday);
    }
    dispatch(updateTicker(games));
  } catch (e) {
    dispatch(updateTicker([]));
    dispatch(fetchTickerFailed(e));
  }
};

// reducer
const initialState = {
  isLoading: false,
  games: [],
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TICKER_STARTED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case FETCH_TICKER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    case UPDATE_TICKER:
      return {
        ...state,
        games: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
