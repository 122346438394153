import Head from 'next/head';

const Teads = () => (
  <Head>
    <script>
      {/* eslint-disable */
      (function() {
        window.teads_analytics = window.teads_analytics || {};
        window.teads_analytics.analytics_tag_id = 'PUB_14345';
        window.teads_analytics.share =
          window.teads_analytics.share ||
          function() {
            (window.teads_analytics.shared_data = window.teads_analytics.shared_data || []).push(
              arguments
            );
          };
        /*eslint-disable*/
      })(window, document, 'script')}
    </script>
    <script async src="https://a.teads.tv/analytics/tag.js" />
  </Head>
);

export default Teads;
