import types from './types';

const initialState = {
  breakingNews: {},
};

const breakingNewsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_BREAKING_NEWS: {
      return {
        ...state,
        breakingNews: payload,
      };
    }
    default:
      return state;
  }
};

export default breakingNewsReducer;
