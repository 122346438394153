import React from 'react';
import StyledComponent from '~/components/Styled/Styled';
import userLocation from '~/util/userLocation';
import Cookie from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import css from '~/components/PrivacyPolicyPopup/PrivacyPolicyPopup.scss';

// prettier-ignore
const shownInCountries = [
  'CA', 'EU', 'AD', 'AL', 'AT', 'BA', 'BE', 'BG', 'BY', 'CH', 'CS', 'CZ',
  'DE', 'DK', 'EE', 'ES', 'FI', 'FO', 'FR', 'FX', 'GB', 'GI', 'GR', 'HR',
  'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'MK', 'MT',
  'NL', 'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SJ', 'SK', 'SM', 'UA', 'VA',
];

class PrivacyPolicyPopup extends React.Component {
  constructor() {
    super();

    this.state = {
      open: true,
      country: '',
    };

    this.userClosedPopup = this.userClosedPopup.bind(this);

    userLocation.promise.then(location => {
      this.countryCode = location.countryCode;

      if (shownInCountries.indexOf(this.countryCode) < 0) {
        this.userClosedPopup();
      }
    });
  }

  userClosedPopup() {
    Cookie.set('privacyPopupShown', true, {
      path: '/',
      expires: 365,
    });

    this.setState({ open: false });
  }

  render() {
    const popupShown = !!Cookie.get('privacyPopupShown');
    return (
      !popupShown && (
        <div className={this.state.open ? css.privacyPopupContainer : css.hidden}>
          <span className={css.privacyPopupContent}>
            <FormattedMessage
              id="privacy_policy.string1"
              defaultMessage="theScore uses cookies for advertising personalization and"
            />{' '}
            <br className={css.lineBreak} />
            <FormattedMessage
              id="privacy_policy.string2"
              defaultMessage="other purposes. By continuing to use our service, you agree to"
            />{' '}
            <br className={css.lineBreak} />
            <FormattedMessage
              id="privacy_policy.string3"
              defaultMessage="our use of cookies."
            />{' '}
            <a className={css.link} href="https://www.thescore.com/pages/privacy">
              <FormattedMessage id="privacy_policy.learn_more" defaultMessage="Learn more." />
            </a>
          </span>

          <button className={css.privacyPopupClose} onClick={this.userClosedPopup}>
            &#x2573;
          </button>
        </div>
      )
    );
  }
}

PrivacyPolicyPopup.displayName = 'PrivacyPolicyPopup';

export default StyledComponent(PrivacyPolicyPopup, [css]);
