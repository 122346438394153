exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Header__header--3AxUE {\n  width: 100%;\n  background-color: #1e1f21;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  z-index: 2;\n  height: 60px;\n}\n\n.Header__headerWrap--1FpXt {\n  width: 100%;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  background-color: #1e1f21;\n  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);\n}\n\n.Header__logoNavBox--2Xi97 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.Header__logoInner--2i1J7 {\n  align-items: center;\n  display: flex;\n}\n\n.Header__navContainer--3Nbf6 {\n  padding-left: 0;\n}\n\n.Header__headerWrap--1FpXt.Header__fixed--2j7PU {\n  position: fixed;\n}\n\n.Header__headerContainer--yilsM {\n  display: flex;\n  max-width: 1024px;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1 1 auto;\n  margin: 0 auto;\n  padding: 0 16px;\n  z-index: 2;\n}\n\n.Header__scheduleWrap--3KRVz {\n  max-width: 1024px;\n  margin: 0 16px;\n}\n\n.Header__downloadButton--2ZRMD {\n  background: #0078fd;\n  width: 113px;\n  height: 34px;\n  border: none;\n  font-size: 12px;\n  color: #fff;\n  display: block;\n  cursor: pointer;\n}\n\n.Header__logoWrapper--RVaMd {\n  padding-top: 2px;\n}\n\n.Header__logo--3szyq {\n  width: 110px;\n  height: 24px;\n  background: url(/static/vectors/thescore-logo.svg) 0 2px no-repeat;\n  display: block;\n  background-size: auto 190%;\n}\n\n.Header__hideOnDesktop--239HN {\n  display: none;\n}\n\n@media (min-width: 992px) {\n  .Header__scheduleWrap--3KRVz {\n    margin: 0 auto;\n  }\n}\n@media (max-width: 992px) {\n  .Header__hideOnMobile--38_eK {\n    display: none;\n  }\n  .Header__hideOnDesktop--239HN {\n    display: block;\n  }\n}", ""]);

// exports
exports.locals = {
	"header": "Header__header--3AxUE",
	"headerWrap": "Header__headerWrap--1FpXt",
	"logoNavBox": "Header__logoNavBox--2Xi97",
	"logoInner": "Header__logoInner--2i1J7",
	"navContainer": "Header__navContainer--3Nbf6",
	"fixed": "Header__fixed--2j7PU",
	"headerContainer": "Header__headerContainer--yilsM",
	"scheduleWrap": "Header__scheduleWrap--3KRVz",
	"downloadButton": "Header__downloadButton--2ZRMD",
	"logoWrapper": "Header__logoWrapper--RVaMd",
	"logo": "Header__logo--3szyq",
	"hideOnDesktop": "Header__hideOnDesktop--239HN",
	"hideOnMobile": "Header__hideOnMobile--38_eK"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };