/* global API_URL */

import fetch, { handleResponse, handleRequestFailure } from './fetch';

const getStandings = async (leagueSlug, apiUri = `${leagueSlug}/standings`, conference = null) =>
  fetch(`${API_URL}/${apiUri}${conference ? `?conference=${conference}` : ''}`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export default getStandings;
