import PropTypes from 'prop-types';
import { Link as WrappedLink } from '~/routes';

const Link = ({ route, onClick, children, prefetch, className = '' }) => (
  /* eslint-disable*/
  <span className={className} onClick={onClick}>
    <WrappedLink route={route} prefetch={prefetch}>
      {children}
    </WrappedLink>
  </span>
  /*eslint-disable*/
);

Link.propTypes = {
  route: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  prefetch: PropTypes.bool,
  className: PropTypes.string
};

Link.defaultProps = {
  onClick: null,
  prefetch: false,
};

export default Link;
