/* eslint-disable */
import React, { Fragment } from 'react';
import Head from 'next/head';

export default function StyledComponent(OtherComponent, stylesheets) {
  const name = OtherComponent.displayName || OtherComponent.name || 'Component';
  class StyledComponent extends React.Component {
    getWrappedInstance() {
      return this.node;
    }

    render() {
      return (
        <Fragment>
          <Head>
            {(stylesheets || []).map((css, _index) => (
              <style
                key={`${name}-${_index}`}
                dangerouslySetInnerHTML={{ __html: css.stylesheet }}
              />
            ))}
          </Head>

          <OtherComponent
            {...this.props}
            ref={c => {
              this.node = c;
            }}
          />
        </Fragment>
      );
    }
  }
  StyledComponent.displayName = `StyledComponent(${name})`;

  return StyledComponent;
}
