exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PrivacyPolicyPopup__link--20k_E {\n  text-decoration: none;\n  color: #006dff;\n}\n\n.PrivacyPolicyPopup__link--20k_E:hover {\n  color: #005abe;\n}\n\n.PrivacyPolicyPopup__link--20k_E:focus {\n  color: #005abe;\n  text-decoration: underline;\n  box-shadow: #005abe 0 0 0 2px !important;\n}\n\n.PrivacyPolicyPopup__privacyPopupContainer--2xTS2 {\n  position: fixed;\n  display: flex;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto;\n  z-index: 1000;\n  width: 100%;\n  max-width: 500px;\n  color: #1e1f21;\n  background: #fff;\n  box-shadow: 0 0 4px #000;\n  align-items: center;\n}\n\n.PrivacyPolicyPopup__privacyPopupContent--2NbTd {\n  display: inline-block;\n  margin: 10px;\n  font-size: 13px;\n  font-weight: 300;\n  line-height: 15px;\n  width: 100%;\n}\n\n.PrivacyPolicyPopup__privacyPopupClose--32kXh {\n  flex-shrink: 0;\n  display: inline-block;\n  width: 26px;\n  height: 26px;\n  margin-left: 24px;\n  margin-right: 16px;\n  line-height: 22px;\n  font-weight: 300;\n  color: #888;\n  border: 1px solid #888;\n  border-radius: 13px;\n  cursor: pointer;\n}\n\n.PrivacyPolicyPopup__hidden--2CEE6 {\n  display: none;\n}\n\n@media (max-width: 767px) {\n  .PrivacyPolicyPopup__lineBreak--C5OPQ {\n    display: none;\n  }\n}", ""]);

// exports
exports.locals = {
	"link": "PrivacyPolicyPopup__link--20k_E",
	"privacyPopupContainer": "PrivacyPolicyPopup__privacyPopupContainer--2xTS2",
	"privacyPopupContent": "PrivacyPolicyPopup__privacyPopupContent--2NbTd",
	"privacyPopupClose": "PrivacyPolicyPopup__privacyPopupClose--32kXh",
	"hidden": "PrivacyPolicyPopup__hidden--2CEE6",
	"lineBreak": "PrivacyPolicyPopup__lineBreak--C5OPQ"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };