/* global API_URL */

import get from 'lodash/get';

import fetch, { handleResponse, handleRequestFailure } from './fetch';

export const getConferences = async leagueSlug =>
  fetch(`${API_URL}/${leagueSlug}/events/conferences`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getDefaultConference = async leagueSlug => {
  const conferences = await getConferences(leagueSlug);

  return get(conferences, 'json[0].conferences[0]', 'Top 25');
};
