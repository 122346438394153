import React from 'react';
import PropTypes from 'prop-types';
import StyledComponent from '~/components/Styled/Styled';
import css from './AlternativeInterstitial.scss';

const AlternativeInterstitial = props => (
  <div className={css.container}>
    <div className={css.caption}>
      If you want the best sports app,<br />
      <span className={css.large}>download theScore</span>
    </div>
    <div className={css.ctaContainer}>
      <img
        className={css.ctaPhoto}
        srcSet="/static/images/apple-i-phone-8-silver.png, /static/images/apple-i-phone-8-silver@2x.png 2x, /static/images/apple-i-phone-8-silver@3x.png 3x"
        src="/static/images/apple-i-phone-8-silver@3x.png"
        alt=""
      />
      <div className={css.gradient} />
    </div>
    <a href={`https://thescore.app.link/4IiNl704fL?campaign=altdesign${props.buttonText.name}`}>
      <button
        className={css.downloadButton}
        onClick={() => {
          props.handleGetTheAppClick('stubpagedownloadCTA', props.experiments);
        }}
      >
        {props.buttonText.label}
      </button>
    </a>
    <a
      onClick={() => {
        props.scrollDown(props.banner);
      }}
      role="button"
      tabIndex={0}
    >
      <div className={css.moreContentText}>Scroll down to see full content</div>
      <div className={css.moreContentChevron}>&#8964;</div>
    </a>
  </div>
);

AlternativeInterstitial.propTypes = {
  handleGetTheAppClick: PropTypes.func,
  scrollDown: PropTypes.func,
  experiments: PropTypes.array,
  banner: PropTypes.node,
  buttonText: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

AlternativeInterstitial.defaultProps = {
  handleGetTheAppClick: () => {},
  scrollDown: () => {},
  experiments: [],
  banner: null,
};

AlternativeInterstitial.displayName = 'AlternativeInterstitial';

export default StyledComponent(AlternativeInterstitial, [css]);
