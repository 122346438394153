import { Experiment, Ops } from 'planout';
import logEvent from '~/util/analytics';

class BreakingNewsExperiment extends Experiment {
  setup() {
    this.setName('breakingNewsExperiment');
  }

  configureLogger() {
    return this;
  }

  log(event) {
    if (event.event === 'exposure') {
      logEvent('experiment', {
        type: 'exposure',
        experiment_name: 'breaking_news_experiment',
        show_breaking_news: event.params.show_breaking_news,
        ...event.params,
      });
    }
    return this;
  }

  previouslyLogged() {
    // check if we’ve already logged an event for this user
    return this._exposureLogged; // eslint-disable-line no-underscore-dangle
  }

  getParamNames() {
    return this.getDefaultParamNames();
  }

  assign(params, args) {
    params.set(
      'show_breaking_news',
      new Ops.Random.BernoulliTrial({
        p: process.env.NODE_ENV === 'production' ? 0.5 : 1,
        unit: args.userId,
      })
    );

    return this;
  }
}

export default BreakingNewsExperiment;
