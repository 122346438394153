import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import StyledComponent from '~/components/Styled/Styled';
import css from './ScrollMenuElement.scss';

const cssContext = classnames.bind(css);

const ScrollMenuElement = props => {
  const classes = cssContext(css.headingBox, {
    firstCard: props.firstCard,
    selected: props.selected,
    navMenu: props.navMenu,
  });

  const onKeyDown = event => {
    // Enter or Space
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      props.onClick(event);
    }
  };

  let additionalProps = {};

  if (props.onClick) {
    additionalProps = {
      role: 'button',
      tabIndex: '0',
      onKeyDown,
      onClick: props.onClick,
    };
  }

  return (
    <div className={classes} id={props.id} {...additionalProps} aria-current={props.ariaCurrent}>
      <div className={css.heading}>{props.name.toUpperCase()}</div>
    </div>
  );
};

ScrollMenuElement.propTypes = {
  firstCard: PropTypes.bool,
  selected: PropTypes.bool,
  navMenu: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  ariaCurrent: PropTypes.string,
};

ScrollMenuElement.defaultProps = {
  firstCard: false,
  selected: false,
  navMenu: false,
  name: '',
  onClick: null,
  id: '',
  ariaCurrent: null,
};

ScrollMenuElement.displayName = 'ScrollMenuElement';

export default StyledComponent(ScrollMenuElement, [css]);
