import types from './types';

const setUserLocation = payload => ({
  type: types.SET_USER_LOCATION,
  payload,
});

export default {
  setUserLocation,
};
