const supportedLeagues = [
  'atp',
  'bund',
  'cfl',
  'chlg',
  'copam',
  'deu_fed',
  'engch',
  'engfa',
  'eng_fed',
  'epga',
  'epl',
  'espcr',
  'esp_fed',
  'eurc',
  'eurcq',
  'euro_fed',
  'fmf_ap',
  'fmf_cl',
  'formula1',
  'fran',
  'fra_fed',
  'gerdfbp',
  'itaci',
  'ita_fed',
  'intfr',
  'liga',
  'lpga',
  'mex_fed',
  'mlb',
  'mls',
  'mma',
  'nascar',
  'nasnw',
  'nba',
  'ncaab',
  'ncaaf',
  'nfl',
  'nhl',
  'pga',
  'seri',
  'top_news',
  'uefa',
  'uefa_fed',
  'us_fed',
  'wcbk',
  'wjhc',
  'wnba',
  'wolym',
  'wolym',
  'wolymhm',
  'wolymhw',
  'wolymh_fed',
  'worldcup',
  'wta',
  'wwcup',
];

// Using a Common JS export here to support use in routes.js
module.exports = supportedLeagues;
