import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '~/routes';
import ScrollMenu from '~/components/ScrollMenu/ScrollMenu';
import ScrollMenuElement from '~/components/DateScheduleElement/DateScheduleElement';
import { connect } from 'react-redux';
import { operations } from '~/state/pageData';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import find from 'lodash/find';

const TODAY = moment().format('YYYY-MM-DD');
const isToday = date => date === TODAY;

class ScheduleDateScrollMenu extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    if (this.props.items.length) this.centerSelectedElement();
  }

  componentDidUpdate(nextProps) {
    if (nextProps === this.props) {
      return;
    }
    if (nextProps.items.length) this.centerSelectedElement();
  }

  onClick(event) {
    const middle =
      (this.node.offsetWidth - event.target.offsetWidth) / 2 - event.target.offsetWidth / 2;
    const nextEvent = find(this.props.items, item => item.id === event.currentTarget.id);
    const leagueSlug = this.props.league.slug;
    const { params, graphQL } = this.props;

    event.preventDefault();

    if (params.conference) {
      Router.pushRoute('eventsByGroupAndConference', {
        leagueSlug,
        section: 'events',
        group: nextEvent.id,
        conference: params.conference,
      });
    } else {
      Router.pushRoute(
        graphQL ? 'graphQLEventsByGroup' : 'eventsByGroup',
        { leagueSlug, group: nextEvent.id, section: 'events' },
        {
          shallow: true,
        }
      );
    }

    if (this.menu) this.menu.getWrappedInstance().scrollTo(event.currentTarget.offsetLeft - middle);
  }

  centerSelectedElement() {
    if (typeof document !== 'undefined') {
      const selected = document.getElementById(this.props.selected);
      if (!selected) {
        return;
      }
      const middle = (this.node.offsetWidth - selected.offsetWidth) / 2 - selected.offsetWidth / 2;

      if (this.menu) this.menu.getWrappedInstance().scrollTo(selected.offsetLeft - middle);
    }
  }

  render() {
    return (
      <div
        ref={node => {
          this.node = node;
        }}
      >
        <ScrollMenu
          height={this.props.height}
          buttons
          ref={c => {
            this.menu = c;
          }}
        >
          {this.props.items.map(date => (
            <ScrollMenuElement
              key={date.id}
              id={date.id}
              name={isToday(date.id) ? 'TODAY' : date.label}
              onClick={this.onClick}
              initialSelected={this.props.initialSelected}
            />
          ))}
        </ScrollMenu>
      </div>
    );
  }
}

ScheduleDateScrollMenu.propTypes = {
  height: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  initialSelected: PropTypes.string,
  league: PropTypes.object.isRequired,
  params: PropTypes.object,
  setEvents: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  graphQL: PropTypes.bool,
};

ScheduleDateScrollMenu.defaultProps = {
  height: '100%',
  items: [],
  initialSelected: null,
  params: {},
  selected: false,
  graphQL: false,
};

const mapStateToProps = state => ({
  league: state.pageData.currentLeague,
  params: state.pageData.routeParams,
  selected: state.pageData.selectedDate,
});

const mapDispatchToProps = dispatch => ({
  setEvents: bindActionCreators(operations.setEvents, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDateScrollMenu);
