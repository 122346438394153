exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Ad__container--MeQWT {\n  background: #000;\n  text-align: center;\n  box-sizing: border-box;\n  outline: 0;\n}\n.Ad__container--MeQWT:focus {\n  border: 2px solid #fff;\n}\n\n.Ad__adText--3SBBt {\n  color: #fff;\n  font-size: 12px;\n  text-transform: uppercase;\n}\n.Ad__bigboxDesktop--3YlS6 .Ad__adText--3SBBt {\n  display: block;\n}\n\n.Ad__bigboxDesktop--3YlS6 {\n  width: 300px;\n  padding: 20px 0;\n  margin: 0 auto;\n  display: none;\n}\n@media (min-width: 1200px) {\n  .Ad__bigboxDesktop--3YlS6 {\n    display: block;\n  }\n}\n\n.Ad__bigboxDesktop--3YlS6 :last-child {\n  min-height: 250px;\n}\n\n.Ad__bigboxMobile--3VRdG {\n  width: 300px;\n  min-height: 250px;\n  padding: 10px 0;\n  margin: 0 auto;\n  display: block;\n}\n@media (min-width: 768px) {\n  .Ad__bigboxMobile--3VRdG {\n    display: none;\n  }\n}\n\n.Ad__leaderboard--2fzKP {\n  text-align: center;\n  background: none;\n  border: 0;\n  padding: 20px 0;\n  min-height: 90px;\n  display: none;\n}\n@media (min-width: 1200px) {\n  .Ad__leaderboard--2fzKP {\n    display: block;\n  }\n}\n\n.Ad__mobileBanner--15qBa {\n  background: none;\n  border: 0;\n  height: 50px;\n  width: 320px;\n  margin: 0 auto;\n}\n\n.Ad__outOfPage--FaM9P {\n  border: 0;\n  display: inline;\n}\n\n.Ad__teads--2Zaub {\n  background: none;\n  margin: 0 auto;\n}\n\n.Ad__wrap--3Y355 {\n  height: 0;\n}\n\n.Ad__wrap--3Y355 div {\n  height: 0;\n}", ""]);

// exports
exports.locals = {
	"container": "Ad__container--MeQWT",
	"adText": "Ad__adText--3SBBt",
	"bigboxDesktop": "Ad__bigboxDesktop--3YlS6",
	"bigboxMobile": "Ad__bigboxMobile--3VRdG",
	"leaderboard": "Ad__leaderboard--2fzKP",
	"mobileBanner": "Ad__mobileBanner--15qBa",
	"outOfPage": "Ad__outOfPage--FaM9P",
	"teads": "Ad__teads--2Zaub",
	"wrap": "Ad__wrap--3Y355"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };