export default {
  SET_LEAGUE: 'SET_LEAGUE',
  SET_SECTION: 'SET_SECTION',
  SET_ROUTE_PARAMS: 'SET_ROUTE_PARAMS',
  SET_AD_CONFIG: 'SET_AD_CONFIG',
  SET_SCHEDULE: 'SET_SCHEDULE',
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENTS_INITIATED: 'SET_EVENTS_INITIATED',
  SET_EVENTS_COMPLETED: 'SET_EVENTS_COMPLETED',
  SET_STANDINGS: 'SET_STANDINGS',
  SET_PLAYOFF_STANDINGS: 'SET_PLAYOFF_STANDINGS',
  SET_WILDCARD_STANDINGS: 'SET_WILDCARD_STANDINGS',
  SET_TEAM_ROSTER: 'SET_TEAM_ROSTER',
  SET_TEAM_SCHEDULE_PREVIOUS: 'SET_TEAM_SCHEDULE_PREVIOUS',
  SET_TEAM_SCHEDULE_CURRENT: 'SET_TEAM_SCHEDULE_CURRENT',
  SET_TEAM_SCHEDULE_UPCOMING: 'SET_TEAM_SCHEDULE_UPCOMING',
  SET_PLAYER: 'SET_PLAYER',
  SET_PLAYER_SUMMARY: 'SET_PLAYER_SUMMARY',
  SET_PLAYER_RECORDS: 'SET_PLAYER_RECORDS',
  SET_LEAGUE_RIVER: 'SET_LEAGUE_RIVER',
  SET_RELATED_NEWS: 'SET_RELATED_NEWS',
  LEAGUE_RIVER_REQUEST: 'LEAGUE_RIVER_REQUEST',
  LEAGUE_RIVER_SUCCESS: 'LEAGUE_RIVER_SUCCESS',
  LEAGUE_RIVER_FAILURE: 'LEAGUE_RIVER_FAILURE',
  SET_STATUS_CODE: 'SET_STATUS_CODE',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_CLEAR_SCHEDULE_AND_EVENTS_DATA: 'SET_CLEAR_SCHEDULE_AND_EVENTS_DATA',
};
