import fetch, { handleResponse, handleRequestFailure } from './fetch';
/* global API_URL */

export const CYCLE_TYPES = {
  full_schedule: 'full_schedule',
  current: 'current',
  previous: 'previous',
  upcoming: 'upcoming',
};

const getTeam = async (leagueSlug, id) =>
  fetch(`${API_URL}/${leagueSlug}/teams/${id}`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getTeamSchedule = async (leagueSlug, id, cycle = 'full_schedule') => {
  if (CYCLE_TYPES[cycle]) {
    return fetch(`${API_URL}/${leagueSlug}/teams/${id}/events/${cycle}`)
      .then(handleResponse)
      .catch(handleRequestFailure);
  }
  return [];
};

export const getTeamStats = async resourceUri =>
  fetch(`${API_URL}/${resourceUri}/profile`)
    .then(handleResponse)
    .then(result => result.json)
    .catch(() => null);

export const getTeamRoster = async (leagueSlug, id) =>
  fetch(`${API_URL}/${leagueSlug}/teams/${id}/players`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export default getTeam;
