import { ExperimentSetup } from 'planout';
import actions from './actions';

const setId = id => dispatch => {
  ExperimentSetup.registerExperimentInput('userId', id);
  dispatch(actions.setId(id));
};

const setMobile = isMobile => dispatch => {
  ExperimentSetup.registerExperimentInput('isMobile', isMobile);
  dispatch(actions.setMobile(isMobile));
};
const setExperiments = actions.setExperiments;
const setInterstitialSeen = actions.setInterstitialSeen;
const setAlertSeen = actions.setAlertSeen;

export default {
  setId,
  setExperiments,
  setMobile,
  setInterstitialSeen,
  setAlertSeen,
};
