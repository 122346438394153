exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GetTheApp__getTheAppContainer--ipC5L {\n  margin: 0 auto;\n  width: 100%;\n  height: 100vh;\n  position: absolute;\n}\n\n.GetTheApp__fullScreenContainerScrolldown--29ZR5 {\n  background: linear-gradient(189.67deg, #0079ff 20.16%, #051f3d 111.49%);\n  margin: 0 auto;\n  max-width: 960px;\n  min-height: 760px;\n  height: 100vh;\n  text-align: center;\n}", ""]);

// exports
exports.locals = {
	"getTheAppContainer": "GetTheApp__getTheAppContainer--ipC5L",
	"fullScreenContainerScrolldown": "GetTheApp__fullScreenContainerScrolldown--29ZR5"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };