import fetchVCR from 'fetch-vcr';

const mode = process.env.VCR_MODE || 'cache';

export const formatFilename = filename => {
  if (!filename) return '';
  const formattedFilename = filename.split('_');

  if (formattedFilename.length < 2) {
    return formattedFilename[0]
      .replace('.raw', '.json')
      .split('GET-3938')
      .map(str => str.substring(0, 50))
      .join('GET-3938');
  }

  return formattedFilename
    .filter(item => !!item === true)
    .filter(item => /date.in/.test(item) === false)
    .slice(1)
    .map(str => str.substring(0, 50))
    .join('-')
    .replace('.raw', '.json');
};

fetchVCR.configure({
  fixturePath: './fixtures/recordings',
  mode,
});

const oldSaveFile = fetchVCR.saveFile;
const oldLoadFile = fetchVCR.loadFile;

fetchVCR.saveFile = (rootPath, filename, contents) => {
  const newFilename = formatFilename(filename);

  return oldSaveFile(rootPath, newFilename, contents);
};

fetchVCR.loadFile = (rootPath, filename, contents) => {
  const newFilename = formatFilename(filename);

  return oldLoadFile(rootPath, newFilename, contents);
};

export default fetchVCR;
