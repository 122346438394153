import types from './types';
import { combineReducers } from 'redux';
import getOr from '~/util/getOr';

const extractStats = event => {
  if (event.event_status === 'pre_game') {
    switch (event.league.sport_name) {
      case 'football':
      case 'hockey':
        return {
          offensive: getOr(event, 'offensive_comparison', {}),
          defensive: getOr(event, 'defensive_comparison', {}),
        };

      case 'basketball':
      case 'soccer':
        return {
          team: getOr(event, 'comparison', {}),
        };

      case 'baseball':
        return {
          team: getOr(event, 'team_splits', {}),
        };

      default:
        return {};
    }
  }

  // All leagues use team_records for post game stats
  return {
    team: getOr(event, 'box_score.team_records', {}),
  };
};

/* Other potential future reducers
 * - teams
 * - event details
 * - league
 * - box_score
 * - line_scores
 * - 3 stars
 * - goalie records
 * - odds (when we do betting)
 *
 * Lots of nested state on matchup we can use reducers to normalize
 */

export const matchupReducer = (
  state = { isLoading: false, error: null, data: {} },
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MATCHUP_INITIATED: {
      return { ...state, isLoading: true, error: null };
    }
    case types.FETCH_MATCHUP_SUCCEEDED: {
      return { ...state, data: payload, isLoading: false };
    }
    case types.FETCH_MATCHUP_FAILED: {
      return { ...state, data: payload, error: true, isLoading: false };
    }
    default:
      return state;
  }
};

export const selectMatchup = state => {
  return state.matchup.matchup;
};

export const teamStatsReducer = (
  state = {
    isLoading: false,
    error: null,
    data: {},
  },
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MATCHUP_INITIATED: {
      return { ...state, isLoading: true, error: null };
    }
    case types.FETCH_MATCHUP_SUCCEEDED: {
      return { ...state, data: extractStats(payload), isLoading: false };
    }
    case types.FETCH_MATCHUP_FAILED: {
      return { ...state, data: payload, error: true, isLoading: false };
    }
    default:
      return state;
  }
};

export const selectTeamStats = state => {
  const teamStats = getOr(state.matchup.teamStats, 'data', {});
  return teamStats;
};

export const selectOffensiveComp = state => {
  const teamStats = getOr(state.matchup.teamStats.data, 'offensive', {});
  return teamStats;
};

export const selectDefensiveComp = state => {
  const teamStats = state.matchup.teamStats.data;
  return getOr(teamStats, 'defensive', {});
};

export default combineReducers({
  matchup: matchupReducer,
  teamStats: teamStatsReducer,
});
