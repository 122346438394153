import fetch, { handleResponse, handleRequestFailure } from './fetch';

/* global API_URL */

export const getEvents = async (leagueSlug, events) =>
  fetch(`${API_URL}/${leagueSlug}/events?id.in=${events.toString()}`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getEvent = async (leagueSlug, eventId) =>
  fetch(`${API_URL}/${leagueSlug}/events/${eventId}`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getEventsByDateRange = async (leagueSlug, dateStart, dateEnd) =>
  fetch(`${API_URL}/${leagueSlug}/events?game_date.in=${dateStart},${dateEnd}`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getEventRecords = async (leagueSlug, eventId, recordType) =>
  fetch(`${API_URL}/${leagueSlug}/events/${eventId}/${recordType}`)
    .then(handleResponse)
    .catch(handleRequestFailure);
