import React from 'react';
import ScrollMenuElement from '~/components/ScrollMenuElement/ScrollMenuElement';
import { operations } from '~/state/pageData';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

interface Props {
  name: string;
  id: string | number;
  selected: string;
  initialSelected: string;
  onClick: Function;
  setSelectedDate: Function;
}

interface State {
  selected: boolean;
}

class DateScheduleElement extends React.Component<Props, State> {
  static displayName = 'DateScheduleElement';

  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected === props.id || this.props.initialSelected === this.props.id,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.id === nextProps.selected });
  }

  shouldComponentUpdate(_, nextState) {
    return this.state.selected !== nextState.selected;
  }

  onClick = event => {
    this.props.setSelectedDate(event.currentTarget.id);
    this.props.onClick(event);
  };

  render() {
    return (
      <ScrollMenuElement
        onClick={this.onClick}
        selected={
          this.props.initialSelected
            ? this.props.initialSelected === this.props.id
            : this.state.selected
        }
        key={this.props.id}
        id={this.props.id}
        name={this.props.name}
      />
    );
  }
}

const mapStateToProps = state => ({
  selected: state.pageData.selectedDate,
});

const mapDispatchToProps = dispatch => ({
  setSelectedDate: bindActionCreators(operations.setSelectedDate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateScheduleElement);
