const nextRoutes = require('next-routes');
const leagues = require('./constants/SupportedLeagues');

const routes = nextRoutes();
const supportedLeagues = `(${leagues.join('|')})`;
// We can comment out the graphql_events route, but golf pages fall back to the
// standard events page thanks to the supported leagues array. Golf is still
// needed in that array to match news pages, so we need to create a temporary
// array which excludes PGA and LPGA.
const supportedLeaguesWithoutGolf = `(${leagues
  .filter(league => league !== 'pga' && league !== 'lpga')
  .join('|')})`;
const EVENTS_ROUTE = `/:leagueSlug${supportedLeaguesWithoutGolf}/:section(events)`;
// const PLAYERS_ROUTE = `/:leagueSlug${supportedLeagues}/:section(players)`;
// const LEADERS_ROUTE = `/:leagueSlug${supportedLeagues}/:section(leaders)`;

routes.add('kicksoffury', '/KicksofFury', 'kicksoffury');
// routes.add(
//  'graphQLEventsByGroup',
//  `/:leagueSlug(pga|lpga)/:section(events)/date/:group`,
//  'graphql_events'
// );
// routes.add('graphql_events', `/:leagueSlug(pga|lpga)/:section(events)`, 'graphql_events');
// routes.add(
//  'graphql_matchups',
//  `/:leagueSlug(pga|lpga)/:section(events)/:eventId`,
//  'graphql_matchups'
// );
routes.add('home', '/', 'league_river');
routes.add('news', '/news', 'news');
routes.add('authors', '/authors', 'authors');

routes.add('article', `/:slug?/:section(news)/:articleId/:title?`, 'article');

routes.add('s', '/s/:contentId', 'content_card');

// routes.add('stats', `${EVENTS_ROUTE}/:eventId/stats`, 'stats');
routes.add('matchup', `${EVENTS_ROUTE}/:eventId`, 'matchup');

// routes.add('players', `${PLAYERS_ROUTE}/:playerId`, 'player');
// routes.add('playerStats', `${PLAYERS_ROUTE}/:playerId/:subSection(stats)`, 'player_stats');
// routes.add('playerInfo', `${PLAYERS_ROUTE}/:playerId/:subSection(info)`, 'player_info');
// routes.add('playerGameLog', `${PLAYERS_ROUTE}/:playerId/:subSection(gamelog)`, 'player_gamelog');

routes.add('team', `/:leagueSlug${supportedLeagues}/teams/:teamId`, 'team');
routes.add('roster', `/:leagueSlug${supportedLeagues}/teams/:teamId/roster`, 'roster');
routes.add('info', `/:leagueSlug${supportedLeagues}/teams/:teamId/info`, 'team_info');
routes.add('schedule', `/:leagueSlug${supportedLeagues}/teams/:teamId/schedule`, 'team_schedule');
routes.add(
  'matchup_article',
  `${EVENTS_ROUTE}/:eventId/:subSection(preview|recap)`,
  'matchup_article'
);
routes.add('team_stats', `/:leagueSlug${supportedLeagues}/teams/:teamId/stats`, 'team_stats');

routes.add('author', '/author/:authorSlug', 'author');

routes.add('events', EVENTS_ROUTE, 'events');

// routes.add(
//   'graphql_leaders',
//   `/:leagueSlug(pga|lpga)/:section(leaders)/:subSection?`,
//   'graphql_leaders'
// );
// routes.add(
//   'graphql_leaders_see_all',
//   `/:leagueSlug(pga|lpga)/:section(leaders)/:subSection?/:category+`,
//   'graphql_leaders_see_all'
// );

// routes.add('leaders', `${LEADERS_ROUTE}/:subSection?`, 'leaders');
// routes.add('leaders_see_all', `${LEADERS_ROUTE}/:subSection?/:category+`, 'leadersSeeAll');

// routes.add(
//   'tournamentKnockouts',
//   `/:leagueSlug${supportedLeagues}/:section(standings)/:subSection(knockout)`,
//   'tournamentKnockouts'
// );

// routes.add(
//   'standingsPlayoffs',
//   `/:leagueSlug(nba|nhl|nfl|mlb)/:section(standings)/:subSection(playoffs)`,
//   'standingsPlayoffs'
// );

// routes.add(
//   'graphql_standings',
//   `/:leagueSlug(pga|lpga)/:section(standings)/:subSection?`,
//   'graphql_standings'
// );

// routes.add(
//   'standings',
//   `/:leagueSlug${supportedLeagues}/:section(standings)/:subSection?`,
//   'standings'
// );

routes.add('eventsByGroup', `${EVENTS_ROUTE}/date/:group`, 'events');
routes.add(
  'eventsByGroupAndConference',
  `${EVENTS_ROUTE}/conference/:conference/date/:group`,
  'events'
);
routes.add('eventsByConference', `${EVENTS_ROUTE}/conference/:conference`, 'events');

routes.add('leagueRiver', `/:leagueSlug${supportedLeagues}`, 'league_river');
routes.add('leagueNews', `/:leagueSlug${supportedLeagues}/:section(news)`, 'league_river');

routes.add('NCAABLanding', '/:leagueSlug(ncaab)/tournament', 'ncaab_landing');
routes.add(
  'NCAABMatchupLanding',
  '/:leagueSlug(ncaab)/:section(events)/:eventId/:subSection(tournament)',
  'ncaab_matchup_landing'
);

routes.add('BixbyLanding', '/bixby', 'bixby_landing');
routes.add('NewsAlertLanding', '/news_alert_landing', 'news_alert_landing');
routes.add('bixbyAlerts', '/bixby-alerts', 'bixby_alerts');

module.exports = routes;
