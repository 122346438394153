import Head from 'next/head';

const salesforceScript = `
  window.Krux||((Krux=function(){Krux.q.push(arguments)}).q=[]);
  (function(){
    var k=document.createElement('script');k.type='text/javascript';k.async=true;
    k.src=(location.protocol==='https:'?'https:':'http:')+'//cdn.krxd.net/controltag/tl6mtrbuw.js';
    var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(k,s);
  }());
`;

const Salesforce = () => (
  <Head>
    <script
      className="kxct"
      data-id="tl6mtrbuw"
      data-timing="async"
      data-version="3.0"
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: salesforceScript }}
    />
  </Head>
);

export default Salesforce;
