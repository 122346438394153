const INSTAGRAM_PHOTO = 'InstagramPhotoCard';
const INSTAGRAM_VIDEO = 'InstagramVideoCard';
const PLAYER_UPDATE = 'PlayerUpdateCard';
const ROTO_NEWS = 'theScoreInjuryCard';
const SOURCED_ARTICLE = 'SourcedArticleCard';
const THE_SCORE_ARTICLE = 'theScoreArticleCard';
const TWITTER_ANIMATED_GIF = 'TwitterAnimatedGifCard';
const TWITTER_GALLERY_CARD = 'TwitterGalleryCard';
const TWITTER_PHOTO = 'TwitterPhotoCard';
const TWITTER_TEXT = 'TwitterTextCard';
const TWITTER_VIDEO = 'TwitterVideoCard';
const TWITTER_VIDEO_STREAM = 'TwitterVideoStreamCard';
const YOU_TUBE = 'YoutubeVideoCard';

const ENABLED_CARD_TYPES = [
  INSTAGRAM_PHOTO,
  INSTAGRAM_VIDEO,
  PLAYER_UPDATE,
  ROTO_NEWS,
  SOURCED_ARTICLE,
  THE_SCORE_ARTICLE,
  TWITTER_ANIMATED_GIF,
  TWITTER_GALLERY_CARD,
  TWITTER_PHOTO,
  TWITTER_TEXT,
  TWITTER_VIDEO,
  TWITTER_VIDEO_STREAM,
  YOU_TUBE,
];

export const CURATED_CARD_TYPES = [
  INSTAGRAM_VIDEO,
  SOURCED_ARTICLE,
  THE_SCORE_ARTICLE,
  TWITTER_ANIMATED_GIF,
  TWITTER_VIDEO,
  TWITTER_VIDEO_STREAM,
  YOU_TUBE,
];

export const VIDEO_CARD_TYPES = [INSTAGRAM_VIDEO, TWITTER_VIDEO, TWITTER_VIDEO_STREAM];

export const ARTICLE_CARD_TYPES = [SOURCED_ARTICLE, THE_SCORE_ARTICLE];

export default {
  ENABLED_CARD_TYPES,
  INSTAGRAM_PHOTO,
  INSTAGRAM_VIDEO,
  PLAYER_UPDATE,
  ROTO_NEWS,
  SOURCED_ARTICLE,
  THE_SCORE_ARTICLE,
  TWITTER_ANIMATED_GIF,
  TWITTER_GALLERY_CARD,
  TWITTER_PHOTO,
  TWITTER_TEXT,
  TWITTER_VIDEO,
  TWITTER_VIDEO_STREAM,
  YOU_TUBE,
};
