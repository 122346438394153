import { initStore } from '~/state/store';

interface AnalyticsWindow extends Window {
  __NEXT_REDUX_STORE__: {
    dispatch: Function;
  };
}

declare var window: AnalyticsWindow;

const logEvent = payload => ({
  payload,
  type: 'LOG_EVENT',
});

export default (event, properties = {}) => {
  const isWindow = typeof window !== 'undefined';
  const store = isWindow ? window.__NEXT_REDUX_STORE__ || initStore() : initStore();

  store.dispatch(logEvent({ event, ...properties }));
};
