import getSchedule from '~/util/getSchedule';
import { getEvents } from '~/util/getEvents';
import { getPlayer, getPlayerSummary, getPlayerRecords } from '~/util/getPlayers';
import { getTeamRoster, getTeamSchedule, CYCLE_TYPES } from '~/util/getTeamData';
import { noLeague } from '~/constants/Leagues';
import ContentCardTypes from '~/constants/ContentCardTypes';
import { getRelatedContentFromLeague, getRiverData, getRelatedContent } from '~/util/getContent';

import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import find from 'lodash/find';
import actions from './actions';

const handleStatusCode = (statusCode, dispatch) => {
  dispatch(actions.setStatusCode(statusCode));
  if (statusCode >= 400) {
    return { result: 'FAILURE', statusCode };
  }
  return { result: 'SUCCESS', statusCode };
};

const handleError = dispatch => {
  dispatch(actions.setStatusCode(404));
  return { result: 'FAILURE', statusCode: 404 };
};

const setLeague = (payload, menu) => dispatch => {
  const leagueSlug = payload || null;
  const league = find(menu.all, item => item.slug === leagueSlug) || noLeague;
  dispatch(actions.setLeague(league));
};

const setRouteParams = payload => dispatch => {
  dispatch(actions.setRouteParams(payload));
};

const setSection = payload => dispatch => {
  const section = payload || null;
  dispatch(actions.setSection(section));
};

const setAdConfig = (payload, page) => dispatch => {
  const content = payload || {};
  const section = page || 'news';

  const config = {
    section,
    articleId: null,
  };

  if (section === 'article') {
    config.articleId = content.id;
  }

  if (content.type === ContentCardTypes.THE_SCORE_ARTICLE) {
    config.section = 'article';
    config.articleId = content.data.id;
  }

  dispatch(actions.setAdConfig(config));
};

const setTeamRoster = (leagueSlug, teamId) => dispatch =>
  getTeamRoster(leagueSlug, teamId)
    .then(response => {
      dispatch(actions.setTeamRoster(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setTeamRoster([]));
      return handleError(dispatch);
    });

const setTeamSchedulePrevious = (leagueSlug, teamId) => dispatch =>
  getTeamSchedule(leagueSlug, teamId, CYCLE_TYPES.previous)
    .then(response => {
      dispatch(actions.setTeamSchedulePrevious(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setTeamSchedulePrevious({}));
      return handleError(dispatch);
    });

const setTeamScheduleCurrent = (leagueSlug, teamId) => dispatch =>
  getTeamSchedule(leagueSlug, teamId, CYCLE_TYPES.current)
    .then(response => {
      dispatch(actions.setTeamScheduleCurrent(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setTeamScheduleCurrent({}));
      return handleError(dispatch);
    });

const setTeamScheduleUpcoming = (leagueSlug, teamId) => dispatch =>
  getTeamSchedule(leagueSlug, teamId, CYCLE_TYPES.upcoming)
    .then(response => {
      dispatch(actions.setTeamScheduleUpcoming(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setTeamScheduleUpcoming({}));
      return handleError(dispatch);
    });

const shouldFetchPlayer = (state, playerId) =>
  get(state.pageData, 'player.id', null) !== parseInt(playerId, 10);

const getPlayerFromState = (state, playerId) =>
  find(state.pageData.roster, ['id', parseInt(playerId, 10)]);

const setPlayer = (leagueSlug, playerId) => (dispatch, getState) => {
  if (!shouldFetchPlayer(getState(), playerId)) {
    return handleStatusCode(304, dispatch);
  }
  const player = getPlayerFromState(getState(), playerId);
  if (player) {
    dispatch(actions.setPlayer(player));
    return handleStatusCode(200, dispatch);
  }
  return getPlayer(leagueSlug, playerId)
    .then(response => {
      dispatch(actions.setPlayer(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setPlayer({}));
      return handleError(dispatch);
    });
};

const setPlayerSummary = (leagueSlug, playerId) => dispatch =>
  getPlayerSummary(leagueSlug, playerId)
    .then(response => {
      dispatch(actions.setPlayerSummary(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setPlayerSummary([]));
      return handleError(dispatch);
    });

const setPlayerRecords = resourceUri => dispatch =>
  getPlayerRecords(resourceUri)
    .then(response => {
      dispatch(actions.setPlayerRecords(response.json));
      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setPlayerRecords([]));
      return handleError(dispatch);
    });

const setEvents = (leagueSlug, events, loader = true) => dispatch => {
  if (loader) {
    dispatch(actions.setEventsInitiated());
  }

  return getEvents(leagueSlug, events)
    .then(response => {
      dispatch(actions.setEvents(response.json));

      if (loader) {
        dispatch(actions.setEventsCompleted());
      }

      return handleStatusCode(response.statusCode, dispatch);
    })
    .catch(() => {
      dispatch(actions.setEvents([]));
      return handleError(dispatch);
    });
};

const setSchedule = (leagueSlug, collectionId = null, conference = null) => dispatch =>
  getSchedule(leagueSlug, conference)
    .then(response => {
      dispatch(actions.setSchedule(response));
      const currentSeason = response.current_season || response.groups;

      if (collectionId) {
        const eventCollection = find(currentSeason, ['id', collectionId]) || {};
        return dispatch(setEvents(leagueSlug, eventCollection.event_ids, false));
      }

      return {};
    })
    .catch(() => {
      const schedule = {
        current_group: '',
        current_season: [],
      };
      dispatch(actions.setSchedule(schedule));
    });

const setLeagueRiver = payload => dispatch => {
  dispatch(actions.setLeagueRiver(payload));
};

const loadRelatedNews = payload => dispatch => {
  dispatch(actions.loadLeagueRiverRequest());
  return getRelatedContent(payload.relatedUri, payload.relatedNews.before)
    .then(data => {
      const newRelatedNews = payload.relatedNews;
      newRelatedNews.relatedContent = newRelatedNews.relatedContent.concat(data.relatedContentList);
      newRelatedNews.relatedContent = uniqBy(newRelatedNews.relatedContent, 'id');
      newRelatedNews.before = data.newBefore;
      dispatch(actions.loadLeagueRiverSuccess());
      dispatch(actions.setRelatedNews(newRelatedNews));
      return handleStatusCode(200, dispatch);
    })
    .catch(() => {
      dispatch(actions.loadLeagueRiverFailure());
      return handleError(dispatch);
    });
};

const loadMoreLeagueRiver = payload => dispatch => {
  dispatch(actions.loadLeagueRiverRequest());
  return getRelatedContentFromLeague(
    payload.league,
    payload.currentCardId,
    payload.before,
    payload.countryCode,
    true
  )
    .then(data => {
      const newRiverData = payload.riverData;
      newRiverData.relatedContent = newRiverData.relatedContent.concat(data.relatedContentList);
      newRiverData.relatedContent = uniqBy(newRiverData.relatedContent, 'id');

      newRiverData.before = data.newBefore;
      dispatch(actions.loadLeagueRiverSuccess());
      dispatch(actions.setLeagueRiver(newRiverData));
      return handleStatusCode(200, dispatch);
    })
    .catch(() => {
      dispatch(actions.loadLeagueRiverFailure());
      return handleError(dispatch);
    });
};

const loadLeagueRiver = payload => dispatch => {
  dispatch(actions.loadLeagueRiverRequest());
  return getRiverData(
    payload.league,
    payload.currentCardId,
    payload.before,
    payload.countryCode,
    true
  )
    .then(data => {
      const newRiverData = payload.riverData;
      newRiverData.relatedContent = data.pinnedContent.content_cards.concat(
        data.relatedContent.relatedContentList
      );
      newRiverData.relatedContent = uniqBy(newRiverData.relatedContent, 'id');
      const scoreArticles = newRiverData.relatedContent.filter(
        c => c.type === ContentCardTypes.THE_SCORE_ARTICLE
      );
      const otherContent = newRiverData.relatedContent.filter(
        c => c.type !== ContentCardTypes.THE_SCORE_ARTICLE
      );
      newRiverData.relatedContent = scoreArticles
        .slice(0, 6)
        .concat(orderBy(scoreArticles.slice(6).concat(otherContent), 'published_at', 'desc'));
      newRiverData.before = data.relatedContent.newBefore;
      dispatch(actions.loadLeagueRiverSuccess());
      dispatch(actions.setLeagueRiver(newRiverData));
      return handleStatusCode(200, dispatch);
    })
    .catch(() => {
      dispatch(actions.loadLeagueRiverFailure());
      return handleError(dispatch);
    });
};

const setSelectedDate = payload => dispatch => {
  dispatch(actions.setSelectedDate(payload));
};

export default {
  setLeague,
  setRouteParams,
  setSection,
  setAdConfig,
  setSchedule,
  setTeamSchedulePrevious,
  setTeamScheduleCurrent,
  setTeamScheduleUpcoming,
  setTeamRoster,
  setEvents,
  setPlayer,
  setPlayerSummary,
  setPlayerRecords,
  setLeagueRiver,
  loadRelatedNews,
  loadMoreLeagueRiver,
  loadLeagueRiver,
  setSelectedDate,
};
