import types from './types';

const setLeague = league => ({
  type: types.SET_LEAGUE,
  payload: league,
});

const setRouteParams = params => ({
  type: types.SET_ROUTE_PARAMS,
  payload: params,
});

const setSection = section => ({
  type: types.SET_SECTION,
  payload: section,
});

const setAdConfig = adConfig => ({
  type: types.SET_AD_CONFIG,
  payload: adConfig,
});

const setSchedule = schedule => ({
  type: types.SET_SCHEDULE,
  payload: schedule,
});

const setEventsInitiated = () => ({
  type: types.SET_EVENTS_INITIATED,
  payload: true,
});

const setEventsCompleted = () => ({
  type: types.SET_EVENTS_COMPLETED,
  payload: false,
});

const setEvents = events => ({
  type: types.SET_EVENTS,
  payload: events,
});

const setStandings = standings => ({
  type: types.SET_STANDINGS,
  payload: standings,
});

const setPlayoffStandings = standings => ({
  type: types.SET_PLAYOFF_STANDINGS,
  payload: standings,
});

const setWildcardStandings = standings => ({
  type: types.SET_WILDCARD_STANDINGS,
  payload: standings,
});

const setTeamRoster = roster => ({
  type: types.SET_TEAM_ROSTER,
  payload: roster,
});

const setTeamSchedulePrevious = schedule => ({
  type: types.SET_TEAM_SCHEDULE_PREVIOUS,
  payload: schedule,
});

const setTeamScheduleCurrent = schedule => ({
  type: types.SET_TEAM_SCHEDULE_CURRENT,
  payload: schedule,
});

const setTeamScheduleUpcoming = schedule => ({
  type: types.SET_TEAM_SCHEDULE_UPCOMING,
  payload: schedule,
});

const setPlayer = player => ({
  type: types.SET_PLAYER,
  payload: player,
});

const setPlayerSummary = playerSummary => ({
  type: types.SET_PLAYER_SUMMARY,
  payload: playerSummary,
});

const setPlayerRecords = playerRecords => ({
  type: types.SET_PLAYER_RECORDS,
  payload: playerRecords,
});

const setLeagueRiver = riverData => ({
  type: types.SET_LEAGUE_RIVER,
  payload: riverData,
});

const setRelatedNews = relatedNews => ({
  type: types.SET_RELATED_NEWS,
  payload: relatedNews,
});

const loadLeagueRiverRequest = () => ({
  type: types.LEAGUE_RIVER_REQUEST,
  payload: true,
});

const loadLeagueRiverSuccess = () => ({
  type: types.LEAGUE_RIVER_SUCCESS,
  payload: false,
});

const loadLeagueRiverFailure = () => ({
  type: types.LEAGUE_RIVER_FAILURE,
  payload: false,
});

const setStatusCode = statusCode => ({
  type: types.SET_STATUS_CODE,
  payload: statusCode,
});

const setSelectedDate = date => ({
  type: types.SET_SELECTED_DATE,
  payload: date,
});

const setClearScheduleAndEventsData = () => ({
  type: types.SET_CLEAR_SCHEDULE_AND_EVENTS_DATA,
  payload: null,
});

export default {
  setLeague,
  setRouteParams,
  setSection,
  setAdConfig,
  setSchedule,
  setEventsInitiated,
  setEventsCompleted,
  setEvents,
  setStandings,
  setPlayoffStandings,
  setWildcardStandings,
  setTeamRoster,
  setTeamScheduleCurrent,
  setTeamSchedulePrevious,
  setTeamScheduleUpcoming,
  setPlayer,
  setPlayerSummary,
  setPlayerRecords,
  setLeagueRiver,
  setRelatedNews,
  loadLeagueRiverRequest,
  loadLeagueRiverSuccess,
  loadLeagueRiverFailure,
  setStatusCode,
  setSelectedDate,
  setClearScheduleAndEventsData,
};
