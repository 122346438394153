import React from 'react';
import { connect } from 'react-redux';
import Ad, { AdTypes } from '~/components/Ad/Ad';

// tslint:disable-next-line variable-name
export { AdTypes };

interface AdProps {
  leagueSlug?: string;
  adType?: string;
  section?: string;
  articleId?: number;
}

class AdContainer extends React.Component<AdProps> {
  static displayName = 'AdContainer';
  static defaultProps = {
    leagueSlug: 'top_news',
    section: '',
    articleId: null,
    adType: AdTypes.BIGBOX_DESKTOP,
  };

  render() {
    const { section, articleId, ...props } = this.props;
    return <Ad {...props} section={section} articleId={articleId} />;
  }
}

const mapStateToProps = state => ({
  section: state.pageData.adConfig.section,
  articleId: state.pageData.adConfig.articleId,
});

export default connect(mapStateToProps)(AdContainer);
