import fetch from '~/util/fetch';

/* global API_URL */

const normalizeSpotlights = spotlights =>
  spotlights.map(spotlight => ({
    ...spotlight,
    type: 'spotlight',
  }));

export default async () =>
  fetch(`${API_URL}/spotlights`)
    .then(response => response.json())
    .then(spotlights => normalizeSpotlights(spotlights))
    .catch(() => []);
