import fetch, { handleResponse, handleRequestFailure } from './fetch';
/* global API_URL */

export const getPlayerRecords = async resourceUri =>
  fetch(`${API_URL}${resourceUri}/player_records`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getPlayer = async (leagueSlug, playerId) =>
  fetch(`${API_URL}/${leagueSlug}/players/${playerId}`)
    .then(handleResponse)
    .catch(handleRequestFailure);

export const getPlayerSummary = async (leagueSlug, playerId) =>
  fetch(`${API_URL}/${leagueSlug}/players/${playerId}/summary`)
    .then(handleResponse)
    .catch(handleRequestFailure);
