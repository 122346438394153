import Head from 'next/head';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { Sticky, StickyContainer } from 'react-sticky';
import PrivacyPolicyPopup from '~/components/PrivacyPolicyPopup/PrivacyPolicyPopup';
import Facebook from '~/components/Facebook/Facebook';
import Footer from '~/components/Footer/Footer';
import GoogleTagManager from '~/components/GoogleTagManager/GoogleTagManager';
import Header from '~/components/Header/Header';
import PixelTracking from '~/components/PixelTracking/PixelTracking';
import Salesforce from '~/components/Salesforce/Salesforce';
import ShowBanner from '~/components/ShowBanner/ShowBanner';
import StyledComponent from '~/components/Styled/Styled';
import Ad, { AdTypes } from '~/containers/AdContainer/AdContainer';
import { Router } from '~/routes';
import gdprCountryCodes from '~/util/constants';
import css from '~/components/Layout/Layout.scss';
import fonts from '~/static/css/fonts.scss';

import loadCSS from 'fg-loadcss';
import UserIsTabbing from '~/components/UserIsTabbing/UserIsTabbing';
import Teads from '../Teads/Teads';

const loadCSSAsync = src => {
  if (typeof document !== 'undefined') {
    loadCSS.loadCSS(src);
  }
};

Router.onRouteChangeStart = _url => {
  NProgress.start();
};

Router.onRouteChangeComplete = _url => {
  window.scrollTo(0, 0);
  NProgress.done();
};

Router.onRouteChangeError = () => NProgress.done();

NProgress.configure({ showSpinner: false });

console.log('testing');

const Layout = ({
  children,
  leagueSlug,
  title,
  showEventsNav,
  hideEventsNavOnMobile,
  showInterstitial,
  showHeader,
  showFooter,
  userCountryCode,
}) => (
  <div id="app-container" className={`${css.container} wrapped-content`}>
    <a className={css.skipToContentLink} href="#main-content">
      Skip to content
    </a>
    {showInterstitial && (
      <div className={css.interstitialContainer}>
        <ShowBanner />
      </div>
    )}
    <StickyContainer>
      <Head>
        <title>{title}</title>

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
          rel="stylesheet"
        />

        <link rel="preload" href="/static/css/grid.css" as="style" />
        <link rel="preload" href="/static/css/layout.css" as="style" />

        <meta charSet="utf-8" />
        <meta property="fb:app_id" content="124052647629735" />
        <meta property="og:site_name" content="theScore.com" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" type="image/png" href="/static/images/favicon.png" />

        <meta
          name="google-site-verification"
          content="WHL02URiqh7UHOzRkgsUZzloEHa6suq6EBCn4m5JFGw"
        />

        {loadCSSAsync('//cdn.jsdelivr.net/gh/kenwheeler/slick@1.7.1/slick/slick.css')}
        {loadCSSAsync('//cdn.jsdelivr.net/gh/kenwheeler/slick@1.7.1/slick/slick-theme.css')}
        {loadCSSAsync('/static/icons/icons.css')}
        {loadCSSAsync('//vjs.zencdn.net/6.2.5/video-js.min.css')}
        {loadCSSAsync('/static/css/nprogress.css')}
      </Head>
      <Salesforce />
      <NoSSR>
        <Facebook />
        <PixelTracking />
        <Teads />
        {/* Show privacy policy to all users in Europe */}
        {gdprCountryCodes.includes(userCountryCode) ? <PrivacyPolicyPopup /> : null}
      </NoSSR>
      <GoogleTagManager />
      <UserIsTabbing />

      <div className={css.container}>
        {showHeader && (
          <div>
            <Sticky>
              {({ style, isSticky }) => (
                <div className={css.headerContainer} style={style}>
                  <Header
                    showEventsNav={showEventsNav}
                    hideEventsNavOnMobile={hideEventsNavOnMobile}
                    interstitialOpen={!isSticky}
                  />
                </div>
              )}
            </Sticky>
          </div>
        )}

        <main id="main-content" className={css.content}>
          {leagueSlug && (
            <div className={css.desktopView}>
              <Ad leagueSlug={leagueSlug} adType={AdTypes.LEADERBOARD} />
            </div>
          )}
          {children}
        </main>

        {showFooter && (
          <footer className={css.footer}>
            <Footer />
          </footer>
        )}
      </div>

      <link rel="stylesheet" type="text/css" href="/static/css/grid.css" />
      <link rel="stylesheet" type="text/css" href="/static/css/layout.css" />
    </StickyContainer>
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  showEventsNav: PropTypes.bool,
  hideEventsNavOnMobile: PropTypes.bool,
  showInterstitial: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  leagueSlug: PropTypes.string,
  userCountryCode: PropTypes.string,
};

Layout.defaultProps = {
  children: null,
  title: 'theScore',
  showEventsNav: false,
  hideEventsNavOnMobile: false,
  showInterstitial: false,
  showHeader: true,
  showFooter: true,
  leagueSlug: null,
  userCountryCode: '',
};

Layout.displayName = 'Layout';

const mapStateToProps = state => ({
  userCountryCode: state.location.countryCode,
});

export default connect(mapStateToProps)(StyledComponent(Layout, [css, fonts]));
