import get from 'lodash/get';
import types from './types';

const leadersReducer = (
  state = { isLoading: false },
  { type, payload, leagueSlug, subSection, splitType = null }
) => {
  switch (type) {
    case types.FETCH_LEADERS_INITIATED:
    case types.FETCH_PLAYER_SPLITS_INITIATED: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case types.FETCH_LEADERS_FAILED:
    case types.FETCH_CATEGORIES_FAILED:
    case types.FETCH_PLAYER_SPLITS_FAILED: {
      return {
        ...state,
        data: null,
        error: payload,
        isLoading: false,
      };
    }

    case types.FETCH_LEADERS_SUCCEEDED: {
      const cache = get(state, 'cache', {});
      cache[leagueSlug] = { ...cache[leagueSlug], [subSection]: payload };

      return {
        ...state,
        cache: { ...state.cache, ...cache },
        data: payload,
        isLoading: false,
      };
    }

    case types.FETCH_CATEGORIES_SUCCEEDED: {
      const cache = get(state, 'cache', {});
      cache[leagueSlug] = { ...cache[leagueSlug], categories: payload };

      return {
        ...state,
        cache: { ...state.cache, ...cache },
      };
    }

    case types.FETCH_PLAYER_SPLITS_SUCCEEDED: {
      const cache = get(state, 'cache', {});
      let playerSplits = get(cache, `${leagueSlug}.playerSplits`, {});
      playerSplits = { ...playerSplits, [splitType]: payload };
      cache[leagueSlug] = { ...cache[leagueSlug], playerSplits };

      return {
        ...state,
        cache: { ...state.cache, ...cache },
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default leadersReducer;
