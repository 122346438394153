import actions from './actions';
import getMenu from '~/util/getMenu';
import getSpotlights from '~/util/getSpotlights';

const fetchMenu = () => async dispatch => {
  dispatch(actions.fetchMenuStarted());

  try {
    const [menu, spotlights] = await Promise.all([getMenu(), getSpotlights()]);
    menu.spotlights = [...spotlights];
    dispatch(actions.fetchMenuSucceeded(menu));
  } catch (e) {
    dispatch(actions.fetchMenuFailed(e));
  }
};

export default {
  fetchMenu,
};
