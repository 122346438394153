import moment from 'moment';
import { getPinnedContent } from '~/util/getContent';
import actions from './actions';

const setBreakingNews = league => dispatch => {
  const currentDate = moment();
  return getPinnedContent(league.resource_uri)
    .then(json => {
      const hoursFromPublish = 2;
      const contentCards = json.content_cards;
      const breakingNews = contentCards.filter(
        card =>
          moment.duration(currentDate.diff(card.published_at)).asHours() <= hoursFromPublish &&
          card.type === 'theScoreArticleCard'
      );

      if (breakingNews.length) {
        dispatch(actions.setBreakingNews(breakingNews[0]));
      } else {
        dispatch(actions.setBreakingNews({}));
      }
    })
    .catch(() => dispatch(actions.setBreakingNews({})));
};

export default {
  setBreakingNews,
};
